import React, { useState } from 'react';
import './Chat.css'; // Ensure you create and import this CSS file
import SendIcon from '../../../assets/Images/send.svg'
import Microphone from '../../../assets/Images/microphone.svg'

const UserMessages = {
  'hi': ["Hello!", "Hi there!"],
  'how are you': ["I'm good, thank you!", "Pretty well, thanks!"],
  'what is your name': ["I'm a chatbot.", "I don't have a name."],
  'bye': ["Goodbye!", "See you later!"],
  'how old are you': ["I am always young."],
  'bro': ["Dude!"],
  'what should i eat today': ["Briyani", "Burger", "Pizza"],
};

function getRandomBotReply(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

function ChatBot() {
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState('');

  const sendMessage = () => {
    const newUserMessage = {
      type: 'user',
      text: userInput
    };
    const botResponse = UserMessages[userInput.toLowerCase()] || ["I don't understand.", "Sorry, can you repeat that?"];
    const newBotMessage = {
      type: 'bot',
      text: getRandomBotReply(botResponse)
    };

    setChatMessages([...chatMessages, newUserMessage, newBotMessage]);

    // Clear input field
    setUserInput('');
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const startVoiceRecognition = () => {
    const recognition = new window.webkitSpeechRecognition() || new window.SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;
 
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setUserInput(transcript);
      sendMessage();
    };
 
    recognition.onerror = (event) => {
      console.error('Speech recognition error', event.error);
    };
 
    recognition.start();
  };

  return (
    <div>
      <div id="chat-messages">
        {chatMessages.map((message, index) => (
          <p key={index} className={`${message.type}-message`} style={{ textAlign: message.type === 'user' ? 'right' : 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{message.type === 'user' ? 'You: ' : 'Bot: '}</span>{message.text}
          </p>
        ))}
      </div>
      <div className="input_field">
        <input
          type="text"
          id="user-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Write your message"
          
        />
       <div>
       <img onClick={startVoiceRecognition}src={Microphone} alt="Send" className="voice-icon"/>
          <img onClick={sendMessage} src={SendIcon} alt="Send" className='sendImage'/>
          </div>
      </div>
    </div>
  );
}

export default ChatBot;