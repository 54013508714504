import React, { useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Chat from "./Chat";
import PDF from "../../../assets/Images/PDF.svg";

const MicrosoftRFP = ({ document, onBackButtonClick }) => {
  const [value, setValue] = useState("1");
  const [selectedContent, setSelectedContent] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "2") {
      setSelectedContent(<Chat />);
    } else {
      setSelectedContent(null);
    }
  };

  const handleButtonClick = (content) => {
    setSelectedContent(content);
  };

  const buttonContents = {
    deadline: (
      <div>
        <Typography
          className="fontstyleeb"
          variant="h6"
          sx={{
            color: "rgb(12,140,233)",
            borderBottom: "1px solid",
            paddingBottom: "5px",
          }}
        >
          DEADLINE :
        </Typography>
        <Typography variant="body1" sx={{ marginTop: "10px" }}>
          The submission deadline for proposal RFP 24-10 is April 24, 2024, at
          5:00 PM. Failure to submit the proposal by this deadline may result in
          the submission being deemed non-responsive.
        </Typography>
      </div>
    ),
    SUBMISSIONMODE: (
      <div>
        <Typography
          className="fontstyleeb"
          variant="h6"
          sx={{
            color: "rgb(12,140,233)",
            borderBottom: "1px solid",
            paddingBottom: "5px",
          }}
        >
          SUBMISSION MODE :
        </Typography>
        <Typography variant="body1" sx={{ marginTop: "10px" }}>
          Mode of Submission: Proposers are required to submit their proposals
          electronically to
          <a href="/"> https://portvanusa.procureware.com/Bids?t-Bids </a>
          Corresponding Email Address: Not provided.
        </Typography>
      </div>
    ),
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <div>
          <Typography variant="h5" className="fontstylee">
            <IconButton>
              <img src={PDF} alt="pdf" width="25px" height="25px" />
            </IconButton>
            {document && document?.doc_name === ""
              ? "Projectscope.pdf"
              : document?.doc_name}
          </Typography>
        </div>
        <Button
          onClick={onBackButtonClick}
          sx={{
            color: "rgb(1,117,255)",
            borderColor: "rgb(1,117,255)",
            "&:hover": { borderColor: "rgb(1,117,255)" },
          }}
          variant="outlined"
        >
          Close
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: { backgroundColor: "rgb(1,117,255)" },
                }} // Change the indicator color
              >
                <Tab
                  label="Default Extracted Answers"
                  className="fontstyleeb text-capitalize"
                  value="1"
                  sx={{
                    fontSize: "14px",
                    "&.Mui-selected": {
                      color: "rgb(1,117,255)", // Change text color when selected
                    },
                  }}
                />
                <Tab
                  label="Chat with PDF"
                  className="fontstyleeb text-capitalize"
                  value="2"
                  sx={{
                    fontSize: "14px",
                    "&.Mui-selected": {
                      color: "rgb(1,117,255)", // Change text color when selected
                    },
                  }}
                />
              </TabList>
            </Box>
          </TabContext>
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
                onClick={() => handleButtonClick(buttonContents.deadline)}
              >
                DEADLINE
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
                onClick={() => handleButtonClick(buttonContents.SUBMISSIONMODE)}
              >
                SUBMISSION MODE
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
              >
                GUIDELINES
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
              >
                SCOPE OF WORK
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
              >
                PROJECT REQUIREMENTS
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
              >
                FEATURES
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
              >
                SUBMISSION FORMAT
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "rgb(12,140,233,0.1)",
                  boxShadow: "0",
                  "&:hover": {
                    backgroundColor: "rgb(12,140,233)",
                    color: "white",
                  },
                }}
                variant="contained"
                fullWidth
              >
                BUDGET RANGE
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>{selectedContent}</Box>
      </Box>

      {/* <Box sx={{ mt: 2 }}>
        <Chat/>
        </Box> */}
    </Box>
  );
};

export default MicrosoftRFP;
