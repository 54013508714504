// AddNewSection.js
import React, { useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Box,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useSnackbar } from '../SnackbarContext'; // Import the Snackbar context

const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image']
  ],
};

const formats = [
  'font',
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'align',
  'link', 'image'
];

const AddNewSection = () => {
  const navigate = useNavigate();
  const [sectionName, setSectionName] = useState('');
  const [description, setDescription] = useState('');
  const { showSnackbar } = useSnackbar(); // Access the Snackbar context

  const handleSave = async () => {
    console.log('Saving section...');
    try {
      const requestData = {
        content_name: sectionName,
        content_description: description,
        content_file: '', // If you have a file input, handle it here
      };

      const response = await axios.post(
        'https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/contentlibrary/addclsection',
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('API response:', response);

      if (response.status === 200) {
        showSnackbar('Section added successfully!');
        navigate('/content-library'); // Navigate to the content library page after success
      }
    } catch (error) {
      console.error('Error adding section:', error);
      showSnackbar('Error adding section. Please try again.', 'error');
    }
  };

  return (
    <Container fluid>
      <Box mt={2}>
        <Paper elevation={3} style={{ padding: 20, borderRadius: 15, width: '100%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">
              <strong>Add New Section</strong>
            </Typography>
            <Button variant="outlined" className='border-primary text-primary text-capitalize rounded-3' onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">
              <strong>Section Name</strong>
            </Typography>
            <TextField
              value={sectionName}
              placeholder='Section Name'
              onChange={(e) => setSectionName(e.target.value)}
              variant='outlined'
              fullWidth
              sx={{ marginTop: 1, '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
            />
          </Box>
          <Box mt={3}>
            <Typography variant="h6">
              <strong>Description</strong>
            </Typography>
          </Box>
          <Box>
            <ReactQuill
              value={description}
              onChange={setDescription}
              modules={modules}
              formats={formats}
              placeholder='Section Description'
              style={{ height: '150px', marginTop: '10px' }}
            />
          </Box>
          <Box mt={3} display="flex" justifyContent="flex-end" marginTop={8}>
            <Button variant="contained" className='bg-primary text-capitalize rounded-3' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default AddNewSection;
