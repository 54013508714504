import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import LoginImg from "../../assets/Images/Loginimg.jpg";
import axios from "axios";
//import { ClipLoader } from 'react-spinners'; // Import the spinner

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(1),

  height: "100%",
  justifyContent: "center",
}));

const Form = styled("form")(({ theme }) => ({
  width: "100%",
  // marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0, 1),
}));

const Media = styled("img")({
  height: "100%",
});

// const SpinnerContainer = styled(Box)({
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: 'rgba(255, 255, 255, 0.8)',
//   zIndex: 9999,
// });

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else if (email.length < 5) {
      setEmailError("Email must be at least 5 characters long");
      valid = false;
    } else {
      setEmailError("");
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      setLoading(true);
      axios
        .post(
          "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/login",
          {
            email: email,
            password: password,
          }
        )
        .then((response) => {
          const { userDetails, access_token } = response.data;

          const userData = {
            userDetails,
            access_token,
          };
          localStorage.setItem("userDetails", JSON.stringify(userData));
          setSnackbarMessage("Login successful!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setTimeout(() => {
            navigate("/dashboard"); // Navigate to the dashboard after successful login
          }, 400);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              setSnackbarMessage("User not found");
            } else {
              setSnackbarMessage(
                error.response.data.message || "Login failed. Please try again."
              );
            }
          } else {
            setSnackbarMessage(
              "An unexpected error occurred. Please try again."
            );
          }
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (emailError && validateEmail(newEmail)) {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (passwordError && newPassword.length >= 6) {
      setPasswordError("");
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid
      container
      component="main"
      display="flex"
      sx={{ height: "100vh", width: window.width }}
    >
      {loading && (
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Grid item xs={12} sm={4} md={7}>
        <Media
          src={LoginImg}
          alt="Welcome"
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
          // className='img-fluid'
        />
      </Grid>

      <Grid item xs={12} sm={8} md={5}>
        <FormContainer>
          <Box
            className="scale-down position-absolute top-0 end-0 d-flex mt-3 mx-2"
            mb={4}
          >
            <Box>
              <Typography variant="h5">Welcome to,</Typography>
              <Typography className="title">MIT BidSmart</Typography>
            </Box>
            <Box sx={{ marginLeft: "16px" }}>
              <Media
                src="Rfplogoicon.png"
                alt="Welcome"
                sx={{ width: "65px", height: "65px" }}
              />
            </Box>
          </Box>
          <div className="w-75 mt-4">
            <Typography className="fs-4">
              <b>Welcome Back!</b>
            </Typography>
            <Typography mb={2} className="loginsimplfyText">
              Simplify your RFP process.
            </Typography>
            <Form noValidate onSubmit={handleSubmit} width="100%">
              <InputLabel>
                <Typography className="labels">Email</Typography>
              </InputLabel>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                    "&:hover": {
                      borderColor: "#0D6EFD", // Outline color on hover
                    },
                    "&.Mui-focused": {
                      borderColor: "#0D6EFD", // Changes the border color to blue when focused
                      "& fieldset": {
                        borderColor: "#0D6EFD", // Ensure the outlined border changes to blue
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Enter Email Address"
                name="email"
                autoComplete="email"
                className="textfield mb-3"
                autoFocus
                value={email}
                onChange={handleEmailChange}
                error={Boolean(emailError)}
                helperText={emailError}
              />
              <InputLabel>
                <Typography className="labels">Password</Typography>
              </InputLabel>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                    "&:hover": {
                      borderColor: "#0D6EFD", // Outline color on hover
                    },
                    "&.Mui-focused": {
                      borderColor: "#0D6EFD", // Changes the border color to blue when focused
                      "& fieldset": {
                        borderColor: "#0D6EFD", // Ensure the outlined border changes to blue
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Enter Password"
                type="password"
                size="medium"
                id="password"
                autoComplete="current-password"
                className="textfield"
                value={password}
                onChange={handlePasswordChange}
                error={Boolean(passwordError)}
                helperText={passwordError}
              />
              <Grid container justifyContent="flex-end">
                <Typography variant="text" color="primary" className="mb-3 ">
                  <Link
                    className="fontstylee link-underline"
                    to="/forgot-password "
                  >
                    Forgot Password?
                  </Link>
                </Typography>
              </Grid>
              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                className=" bg-primary text-capitalize rounded-4"
                sx={{
                  padding: "10px 10px 10px 10px",
                  fontWeight: "bold",
                  fontSize: 14,
                }}
                disabled={loading}
              >
                Log In
              </SubmitButton>
              <Typography
                type="button"
                fullWidth
                variant="outlined"
                className="border-primary text-primary text-capitalize rounded-4 ms-5"
                sx={{ padding: "10px 10px 10px 10px" }}
              >
                <span className="fontstylee text-secondary fs-6">
                  New to MIT BidSmart?
                </span>{" "}
                <Link className="fontstylee link-underline" to="/signup">
                  Create New Account
                </Link>
              </Typography>
            </Form>
          </div>
        </FormContainer>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ marginTop: "50px" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default LoginPage;
