import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Card, TextField, Button, Select, MenuItem, Typography, Box, FormControl, Container,ListSubheader } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { format } from 'date-fns';
import dayjs from 'dayjs';
// import './MicrosoftrfpEdit.css';

const EditRFP = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        id: '',
        first_review: '',
        rfp_issuer: '',
        rfp_type: '',
        location: '',
        budget_range: '',
        expected_timeline: '',
        website: '',
        contact_name: '',
        contact_email: '',
        conference_date: '',
        conference_information: '',
        questionnaire_date: '',
        status: '',
        submission_mode: '',
        submission_date: '',
        remarks: '',
        comments: ''
    });
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false); // Track if form is submitted
    const [isEditable, setIsEditable] = useState(true); // Track if the form is editable
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log('Location State:', location.state); // Check the data being passed
        if (location.state && location.state.formData) {
            const updatedFormData = {
                ...location.state.formData,
                first_review: location.state.formData.first_review ? formatDate(location.state.formData.first_review) : '',
                conference_date: location.state.formData.conference_date ? formatDate(location.state.formData.conference_date) : '',
                questionnaire_date: location.state.formData.questionnaire_date ? formatDate(location.state.formData.questionnaire_date) : '',
                submission_date: location.state.formData.submission_date ? formatDate(location.state.formData.submission_date) : '',
            };
            console.log('Updated Form Data:', updatedFormData); // Check the transformed data
            setFormData(updatedFormData);
        }
    }, [location.state]);
    


    useEffect(() => {
        if (isSubmitted) {
            setIsFormValid(validateForm());
        }
    }, [formData, isSubmitted]);

    const formatToISO = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const formControlStyles = {
        '& .MuiOutlinedInput-root': { borderRadius: '10px' },
        '& .MuiInputBase-root': { height: '40px' }
    };

    const selectStyles = {
        '& .MuiOutlinedInput-root': { borderRadius: '10px' },
        '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
        '& .MuiSelect-icon': { top: '8px', color: 'blue' },
        '& .MuiOutlinedInput-input': { paddingTop: '9px' }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'first_review' || name === 'expectedTimeline' || name === 'conference_date' || name === 'questionnaire_date' || name === 'submission_date') {
            console.log(`${name}:`, value);
        }
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleRfpCancel = () => {
        navigate('/dashboard');
    };

    const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');

    const validateForm = () => {
        const newErrors = {};

        if (!formData.first_review) newErrors.first_review = 'First review date is required';
        if (!formData.rfp_issuer) newErrors.rfp_issuer = 'RFP issuer is required';
        if (!formData.rfp_type) newErrors.rfp_type = 'RFP type is required';
        if (!formData.location) newErrors.location = 'Location is required';
        if (!formData.budget_range) newErrors.budget_range = 'Budget range is required';
        if (!formData.expected_timeline) newErrors.expected_timeline = 'Expected timeline is required';
        if (!formData.website) newErrors.website = 'Website is required';
        if (!formData.contact_name) newErrors.contact_name = 'Contact name is required';
        if (!formData.contact_email) newErrors.contact_email = 'Contact email is required';
        if (!formData.conference_date) newErrors.conference_date = 'Conference date is required';
        if (!formData.questionnaire_date) newErrors.questionnaire_date = 'Questionnaire date is required';
        if (!formData.submission_date) newErrors.submission_date = 'Submission date is required';
        if (!formData.status) newErrors.status = 'Status is required';
        if (!formData.submission_mode) newErrors.submission_mode = 'Submission mode is required';
        if (!formData.remarks) newErrors.remarks = 'Remarks are required';
        if (!formData.comments) newErrors.comments = 'Comments are required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSave = async () => {
        setIsSubmitted(true);

        if (!validateForm()) return; // Prevent save if validation fails

        try {


            const requestData = {
                rfpid: id,
                first_review: formatDate(formData.first_review),
                rfp_issuer: formData.rfp_issuer,
                rfp_type: formData.rfp_type,
                location: formData.location,
                budget_range: formData.budget_range,
                expected_timeline: formData.expected_timeline,
                website: formData.website,
                contact_name: formData.contact_name,
                contact_email: formData.contact_email,
                conference_date: formatDate(formData.conference_date),
                conference_information: formData.conference_information,
                questionnaire_date: formatDate(formData.questionnaire_date),
                submission_mode: formData.submission_mode,
                submission_date: formatDate(formData.submission_date),
                status: formData.status,
                remarks: formData.remarks,
                comments: formData.comments
            };
   

            console.log('Request Data:', requestData);

            // Make the API call
            const response = await axios.put('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/editrfp', requestData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Handle success
            console.log('RFP updated successfully', response.data);
            setIsEditable(false); // Disable fields after successful update
            navigate(-1); // Navigate after successful update

        } catch (error) {
            // Handle error
            console.error('Error updating RFP:', error);
        }
    };

    const currentDate = dayjs();
    const minDate = currentDate.subtract(1, 'month').format('yyyy-MM-dd');
    const maxDate = currentDate.add(1, 'month').format('yyyy-MM-dd');
    const minDateConference = currentDate.format('yyyy-MM-dd');
    const maxDateConference = currentDate.add(1, 'month').format('yyyy-MM-dd');
    const minDateQuestionnaireDate = currentDate.format('yyyy-MM-dd');
    const maxDateQuestionnaireDate = currentDate.add(1, 'month').format('yyyy-MM-dd');
    const minDateSubmissionDate = currentDate.format('yyyy-MM-dd');
    const maxDateSubmissionDate = currentDate.add(1, 'month').format('yyyy-MM-dd');

    return (
        <Container>
            <div>
                <Card>
                    <Box style={{ maxHeight: '83vh', overflowY: 'auto', paddingBottom: '16px' }}>
                        <div style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white', padding: '16px 0' }}>
                            <Typography variant="h4" gutterBottom className=' mx-3'>
                                {/* {state && state.formData ? 'Edit RFP Details' : 'Add RFP Details'} */}
                                Edit RFP Details
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="outlined"

                                    sx={{ marginRight: 3, marginTop: -4 }}
                                    className='text-primary border-1px primary border-primary rounded-2'
                                    onClick={()=> navigate(-1)}
                                >
                                    Back
                                </Button>
                            </Box>
                        </div>
                        <form style={{ marginLeft: '15px', marginTop: '6px' }}>
                            <Container>
                                {/* RFP Details Section */}
                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        padding: '16px',
                                        position: 'relative'
                                    }}
                                    className='mb-5'
                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '20px',
                                            background: '#fff',
                                            padding: '0 8px',
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        RFP Details
                                    </Typography>
                                    <Row className='pb-2'>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography >First Review</Typography>
                                                <TextField
                                                    type="date"
                                                    name="first_review"
                                                    value={formData.first_review}
                                                    onChange={handleInputChange}
                                                    placeholder="Select date"
                                                    inputProps={{
                                                        min: minDate,
                                                        max: maxDate
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.first_review} // Show errors only if submitted
                                                    helperText={isSubmitted && errors.first_review}
                                                    disabled={!isEditable}

                                                />

                                            </FormControl>
                                        </Col>
                                        <Col lg={6}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography >RFP ISSUER</Typography>
                                                <TextField
                                                    placeholder="Issuer Name"
                                                    name="rfp_issuer"
                                                    onChange={handleInputChange}
                                                    value={formData.rfp_issuer}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.rfp_issuer}
                                                    helperText={isSubmitted && errors.rfp_issuer}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Rfp Type</Typography>
                                                <Select
                                                    value={formData.rfp_type}
                                                    onChange={handleInputChange}
                                                    name="rfp_type"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderRadius: '10px',
                                                            height: '45px',
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            top: '8px',
                                                            color: 'blue',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            paddingTop: '9px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.rfp_type}
                                                    disabled={!isEditable}
                                                >
                                                            <ListSubheader className='subheader'>Solutioning</ListSubheader>
                                                <MenuItem className='menuItem' value="Web Design">Web Design</MenuItem>
                                                <MenuItem className='menuItem' value="Cloud">Cloud</MenuItem>
                                                <MenuItem className='menuItem' value="ERP">ERP</MenuItem>
                                                <MenuItem className='menuItem' value="Branding">Branding</MenuItem>
                                                <MenuItem className='menuItem' value="SEO & Marketing">SEO & Marketing</MenuItem>

                                                {/* Staffing Section */}
                                                <ListSubheader className='subheader'>Staffing</ListSubheader>
                                                <MenuItem className='menuItem' value="Resource">Resource</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Location</Typography>
                                                <TextField
                                                    placeholder="Location"
                                                    name="location"
                                                    value={formData.location}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}

                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.location}
                                                    helperText={isSubmitted && errors.location}
                                                    disabled={!isEditable}
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Budget Range</Typography>
                                                <TextField
                                                    placeholder="Budget Range"
                                                    name="budget_range"
                                                    value={formData.budget_range}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.budget_range}
                                                    helperText={isSubmitted && errors.budget_range}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Expected Timeline</Typography>
                                                <TextField
                                                    placeholder="Expected Timeline"
                                                    name="expected_timeline"
                                                    value={formData.expected_timeline}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.expected_timeline}
                                                    helperText={isSubmitted && errors.expected_timeline}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Website</Typography>
                                                <TextField
                                                    placeholder="Website"
                                                    name="website"
                                                    value={formData.website}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.website}
                                                    helperText={isSubmitted && errors.website}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box>
                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        padding: '16px',

                                        position: 'relative'
                                    }}
                                    className='mb-5'

                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '20px',
                                            background: '#fff',
                                            padding: '0 8px',
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Point of Contact Details
                                    </Typography>
                                    <Row>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Name</Typography>
                                                <TextField
                                                    placeholder="contact_name"
                                                    name="contact_name"
                                                    value={formData.contact_name}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.contact_name}
                                                    helperText={isSubmitted && errors.contact_name}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Email</Typography>
                                                <TextField
                                                    placeholder="contact_email"
                                                    name="contact_email"
                                                    value={formData.contact_email}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.contact_email}
                                                    helperText={isSubmitted && errors.contact_email}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box>
                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        padding: '16px',

                                        position: 'relative'
                                    }}
                                    className='mb-5'
                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '20px',
                                            background: '#fff',
                                            padding: '0 8px',
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Submission Details
                                    </Typography>
                                    <Row className='pb-2'>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Conference Date</Typography>
                                                <TextField
                                                    type="date"
                                                    name="conference_date"
                                                    value={formData.conference_date}
                                                    onChange={handleInputChange}
                                                    placeholder="Select date"
                                                    inputProps={{
                                                        min: minDateConference,
                                                        max: maxDateConference
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.conference_date}
                                                    helperText={isSubmitted && errors.conference_date}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Conference Information</Typography>
                                                <TextField
                                                    placeholder="Conference Information"
                                                    name="conference_information"
                                                    value={formData.conference_information}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.conference_information}
                                                    helperText={isSubmitted && errors.conference_information}
                                                    disabled={!isEditable}


                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Questionnaire Date</Typography>
                                                <TextField
                                                    type="date"
                                                    name="questionnaire_date"
                                                    value={formData.questionnaire_date}
                                                    onChange={handleInputChange}
                                                    placeholder="Select date"
                                                    inputProps={{
                                                        min: minDateQuestionnaireDate,
                                                        max: maxDateQuestionnaireDate
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.questionnaire_date}
                                                    helperText={isSubmitted && errors.questionnaire_date}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Status</Typography>
                                                <Select
                                                    value={formData.status}
                                                    onChange={handleInputChange}
                                                    name="status"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                            top: '2px'
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderRadius: '10px',
                                                            height: '45px',
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            top: '8px',
                                                            color: 'blue',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            paddingTop: '9px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.status}
                                                    helperText={isSubmitted && errors.status}
                                                    disabled={!isEditable}

                                                >
                                                    <MenuItem value="1">Active</MenuItem>
                                                    <MenuItem value="2">In-Progress</MenuItem>
                                                    <MenuItem value="3">On-Hold</MenuItem>
                                                    <MenuItem value="4">Submitted</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Submission Mode</Typography>
                                                <Select
                                                    value={formData?.submission_mode}
                                                    onChange={handleInputChange}
                                                    name="submission_mode"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderRadius: '10px',
                                                            height: '45px',
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            top: '8px',
                                                            color: 'blue',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            paddingTop: '9px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.submission_mode}
                                                    helperText={isSubmitted && errors.submission_mode}
                                                    disabled={!isEditable}

                                                >
                                                    <MenuItem value="Portal">Portal</MenuItem>
                                                    <MenuItem value="Email">Email</MenuItem>
                                                    <MenuItem value="Physical">Physical</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Submission Date</Typography>
                                                <TextField
                                                    type="date"
                                                    name="submission_date"
                                                    value={formData.submission_date}
                                                    onChange={handleInputChange}
                                                    placeholder="Select date"
                                                    inputProps={{
                                                        min: minDateSubmissionDate,
                                                        max: maxDateSubmissionDate
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.submission_date}
                                                    helperText={isSubmitted && errors.submission_date}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box>
                                {/* <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        padding: '16px',

                                        position: 'relative'
                                    }}

                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '20px',
                                            background: '#fff',
                                            padding: '0 8px',
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Additional Details
                                    </Typography>
                                    <Row>
                                        <Col lg={5}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Remarks</Typography>
                                                <TextField
                                                    placeholder="Remarks"
                                                    name="remarks"
                                                    value={formData.remarks}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.remarks}
                                                    helperText={isSubmitted && errors.remarks}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={5}>
                                            <FormControl fullWidth margin="normal">
                                                <Typography>Comments</Typography>
                                                <TextField
                                                    placeholder="Comments"
                                                    name="comments"
                                                    value={formData.comments}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.comments}
                                                    helperText={isSubmitted && errors.comments}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box> */}
                                <Box sx={{ display: "flex", justifyContent: 'end', marginTop: '10px' }}>
                                    <Button
                                        variant="contained"
                                        className={`bg-primary text-capitalize rounded-3 `}
                                        onClick={handleSave}
                        
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Container>
                        </form>
                    </Box>
                </Card>
            </div>
        </Container>
    );
};

export default EditRFP;