import React, { useState } from 'react';
import { Box, Typography, Button, Container, IconButton, Card, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import RotateRightIcon from '@mui/icons-material/RotateRight';
 
const DocumentViewer = () => {
    const { docUrl } = useParams();
    const navigate = useNavigate();
    const decodedUrl = decodeURIComponent(docUrl);
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [loading, setLoading] = useState(true); // Loading state
 
    const handleZoomChange = (direction) => {
        setZoom(prevZoom => {
            if (direction === 'in') return Math.min(prevZoom + 0.01, 2);
            if (direction === 'out') return Math.max(prevZoom - 0.01, 0.5);
            return prevZoom;
        });
    };
 
    const handleRotate = () => {
        setRotation(prev => (prev + 90) % 360);
    };
 
    return (
        <Container sx={{ backgroundColor: '#FFF', borderRadius: '8px', padding: '16px', width: '97%', maxWidth: 'none' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
                <Typography variant="h5">Microsoft RFP Details</Typography>
                <Button variant="outlined" onClick={() => navigate(-1)} className='border-primary text-primary text-capitalize rounded-3'>
                    Back
                </Button>
            </Box>
 
            <Card sx={{ backgroundColor: '#000', padding: '10px', borderRadius: '4px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ color: '#FFF', display: 'inline-block', margin: '0 10px' }}>Microsoft RFP Details</Typography>
 
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button onClick={() => handleZoomChange('out')} sx={{ color: '#FFF' }}>-</Button>
                        <Typography sx={{ color: '#FFF', display: 'inline-block', margin: '0 10px' }}>{Math.round(zoom * 100)}%</Typography>
                        <Button onClick={() => handleZoomChange('in')} sx={{ color: '#FFF' }}>+</Button>
 
                        <IconButton onClick={handleRotate} sx={{ color: '#FFF', marginLeft: '10px' }}>
                            <RotateRightIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Card>
 
            <Card sx={{ position: 'relative', overflow: 'hidden', borderRadius: '4px', height: '600px' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Center vertically
                        backgroundColor: '#FFF', // Black background for better contrast
                        zIndex: loading ? 10 : -1, // Ensure the loader is above the iframe
                    }}
                >
                    {loading && <CircularProgress className='text-primary' />} {/* Centered loader */}
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        transform: `rotate(${rotation}deg)`,
                        transition: 'transform 0.3s ease',
                    }}
                >
                    <iframe
                        src={`https://docs.google.com/gview?url=${decodedUrl}&embedded=true`}
                        title="Document Viewer"
                        width="100%"
                        height="100%"
                        onLoad={() => setLoading(false)} // Hide spinner when loaded
                        style={{
                            border: 'none',
                            transform: `scale(${zoom})`,
                            transformOrigin: 'top left',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Box>
            </Card>
        </Container>
    );
};
 
export default DocumentViewer;