import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, TextField, IconButton, InputAdornment
} from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
 
function Content_Library() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    const storedData = JSON.parse(localStorage.getItem('userDetails'));
    if (storedData && storedData.userDetails) {
      const userId = storedData.userDetails.id;
      const accessToken = storedData.access_token;
      if (userId && accessToken) {
        axios.post(
          'https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/logout',
          { userid: userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((response) => {
            if (response.status === 200) {
              localStorage.removeItem('userDetails');
              localStorage.removeItem('AlertPopupResponse');
              console.log('Logout successful');
              navigate('/');
            } else {
              console.error('Logout failed');
            }
          })
          .catch((error) => {
            console.error('Error during logout:', error);
          });
      } else {
        console.error('User ID or access token not found in userDetails');
      }
    } else {
      console.error('No userDetails found in localStorage');
    }
  };
 
  useEffect(() => {
    const fetchData = async () => {
      const storedData = JSON.parse(localStorage.getItem('userDetails'));
      let accessToken = storedData.access_token;
 
      const url = `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/contentlibrary/contentlibrary_list?search=${searchTerm}&page=0&pageSize=10`;
 
      try {
        setLoading(true);
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
 
        setData(response.data.data);
        console.log(response.data.data, 'content');
      } catch (error) {
        console.error('Error fetching data:', error);
        if(error.status === 401){
          handleLogout();
        }
      } finally {
        setLoading(false);
      }
    };
 
    fetchData();
  }, [searchTerm]);
 
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
 
  const handleClearSearch = () => {
    setSearchTerm('');
  };
 
  const filteredData = searchTerm.length > 3
    ? data.filter(row =>
      row.content_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : data;
 
  const handleRowClick = (row) => {
    navigate(`/section-details/${row.id}`, { state: { row } });
  };
 
  return (
    <Container fluid maxWidth="100%" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', bgcolor: 'white', flexGrow: 1, mt: 2, boxShadow: 10, borderRadius: '10px', padding: '20px', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>Content Library</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              placeholder="Search by RFP Name"
              variant="outlined"
              onChange={handleSearchChange}
              size="small"
              sx={{
                marginRight: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '9px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ opacity: '40%' }} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearSearch}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" onClick={() => navigate('/add-new-section')} className='bg-primary text-capitalize rounded-3' sx={{ bgcolor: '#429bf5' }}>Add New Section</Button>
          </Box>
        </Box>
 
        <TableContainer component={Paper} sx={{ width: '100%', overflow: 'hidden', boxShadow: 2, padding: 2, borderRadius: '15px', flexGrow: 1 }}>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1000,
              }}
            >
              <CircularProgress className=' text-primary' />
            </Box>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>S.No</TableCell>
                <TableCell sx={{ fontWeight: 600, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sections Name</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Last Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ fontSize: '14px' }}>{index + 1}</TableCell>
                  <TableCell
                    className='fontstylee text-primary'
                    sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', fontSize: '13px', cursor: 'pointer' }}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.content_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: '13px' }}>
                    {/* Safely render the HTML content */}
                    <div dangerouslySetInnerHTML={{ __html: row.content_description }} />
                  </TableCell>
                  <TableCell sx={{ fontSize: '13px' }}>{row.last_updated}</TableCell>
                </TableRow>
              ))}
            </TableBody>
 
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}
 
export default Content_Library;
