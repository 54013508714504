import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Grid, InputLabel, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Arrow from '../../assets/Images/Arrow.svg'

const Root = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  height: '100%',
  justifyContent: 'center',
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const Media = styled('img')({
  height: '100%',
});

const SpinnerContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1300, // Ensure it is above other content
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: slightly opaque background
  borderRadius: '8px', // Optional: rounded corners
  padding: theme.spacing(2), // Optional: add some padding
}));

const OtpVerify = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, ''); // Allow only numeric input

    if (value.length === 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input box if available
      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('Text').replace(/[^0-9]/g, ''); // Get only numeric paste data

    if (pasteData.length > 0) {
      const newOtp = [...otp];
      for (let i = 0; i < pasteData.length && i < otp.length; i++) {
        newOtp[i] = pasteData[i];
      }
      setOtp(newOtp);

      // Focus the next empty box or the last one if all are filled
      const nextIndex = pasteData.length < otp.length ? pasteData.length : otp.length - 1;
      document.getElementById(`otp-input-${nextIndex}`).focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      } else {
        setOtp([...otp.map((d, idx) => (idx === index ? '' : d))]);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (otp.some((digit) => digit === '' || isNaN(digit))) {
      setError('Please enter a valid 6-digit OTP.');
      return;
    }
    
    setError('');
    setLoading(true); // Start loading
    
    try {
      const response = await axios.post('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/otpverify', {
        otp: Number(otp.join('')),
        email: state?.email,
      });
  
      const { userid } = response.data;
      navigate(`/new-password/${userid}`, { state: { email: state?.email } });
    } catch (err) {
      console.error('Error verifying OTP:', err);
      
      // Check if the error is due to an incorrect OTP
      if (err.response && err.response.status === 400) {
        setError('The OTP entered is incorrect. Please try again.');
      } else {
        setError('The OTP entered is incorrect. Please try again.');
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };
  

  const handleResendOtp = async (event) => {
    event.preventDefault();
    console.log('Resend OTP clicked');
    setLoading(true); // Start loading

    try {
      const response = await axios.post('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/forgetpassword', {
        email: state?.email,
      });

      console.log('Resend OTP Response:', response);

      setSnackbarMessage('OTP resent successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setCanResend(false);
      setTimer(60); // Reset the timer
    } catch (err) {
      console.error('Error resending OTP:', err);
      if (err.response && err.response.status === 404) {
        navigate('/notfoundd');
      } else {
        setSnackbarMessage('Failed to resend OTP. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Root>
      <Grid container component="main" display='flex' sx={{ height: '100vh', width: '100%' }}>
        <Grid item xs={false} sm={4} md={7}>
          <Media
            src="Loginimg.jpg"
            alt="Welcome"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}

          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} className='position-relative' >
          <FormContainer sx={{ height: '100%' }}>
            <Box className='position-absolute top-0 start-0 d-flex mt-4  mb-4 arrowIcon'>
              <img src={Arrow} alt="Arrow Icon" onClick={() => navigate('/')} className='mt-2' />
            </Box>
            <Box className='scale-down position-absolute top-0 end-0 d-flex mt-3 mx-2' mb={4}>
            <Box>
              <Typography variant="h5">Welcome to,</Typography>
              <Typography className='title'>
                 MIT BidSmart
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '16px' }}>
              <Media src="Rfplogoicon.png" alt="Welcome" sx={{ width: '65px', height: '65px' }} />
            </Box>
          </Box>
            <Box width={'80%'}>
              <Typography variant="h3">
                <strong>Verify OTP?</strong>
              </Typography>
              <Typography mt={1} mb={1} className='loginsimplfyText'>
                Enter OTP Sent to Your Email
              </Typography>
              <Typography variant='h5' mb={3}>
                {state?.email}<Link to="/forgot-password"> Edit</Link>
              </Typography>
              <Form noValidate onSubmit={handleSubmit}>
                <InputLabel>
                  <Typography className='labels'>
                    OTP
                  </Typography>
                </InputLabel>
                <Box display="flex" justifyContent="space-between">
                  {otp.map((data, index) => (
                    <TextField
                      key={index}
                      id={`otp-input-${index}`}
                      variant="outlined"
                      margin="normal"
                      required
                      className='textfield'
                      inputProps={{
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      }}
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onPaste={handlePaste}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '15px',
                          width: '3rem',
                          height: '3rem',
                          '&:hover': {
                            borderColor: '#0D6EFD', // Outline color on hover
                          },
                          '&.Mui-focused': {
                            borderColor: '#0D6EFD', // Changes the border color to blue when focused
                            '& fieldset': {
                              borderColor: '#0D6EFD', // Ensure the outlined border changes to blue
                            },
                          },
                        },
                      }}
                    />
                  ))}
                </Box>
                <Typography variant="body2" color="textSecondary" mt={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      {!canResend && `Resend OTP in ${timer}s`}
                    </Box>
                    <Box>
                      {canResend && (
                        <Link href="#" onClick={handleResendOtp}>
                          Resend OTP
                        </Link>
                      )}
                    </Box>
                  </Box>
                </Typography>
                {error && (
                  <Typography color="error" variant="body2" mt={2}>
                    {error}
                  </Typography>
                )}
                <SubmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  className='bg-primary text-capitalize rounded-4'
                  sx={{ padding: '10px 10px 10px 10px' }}
                >
                  Verify OTP
                </SubmitButton>
              </Form>
            </Box>
          </FormContainer>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          action={
            <Button color="inherit" onClick={handleCloseSnackbar}>
              Close
            </Button>
          }
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Root>
  );
};

export default OtpVerify;
