import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import documentSigned from "../../assets/Images/document-signed.png";
import SubmittedMemoCircleCheckImage from "../../assets/Images/memo-circle-check.png";
import clockThreeImage from "../../assets/Images/clock-three.png";
import InprogresshourglassStart from "../../assets/Images/hourglass-start.png";
import FormControl from "@mui/material/FormControl";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  RadioGroup,
  CardActions,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Radio from "@mui/material/Radio";
import Modal from "@mui/material/Modal";
import { Close as CloseIcon } from "@mui/icons-material";
import ActiveData from "./Tables/ActiveData";
import SubmittedData from "./Tables/SubmittedData";
import InProgresData from "./Tables/InProgresData";
import OnHoldData from "./Tables/OnHoldedData";
import AlertError from "../../assets/Images/AlertError.svg";
import FileUpload from "./fileUpload";
import { DateRangePicker, Stack } from "rsuite";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },

  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
];

function Dashboard() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDueDate, setSelectedDueDate] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeTable, setActiveTable] = useState("ActiveCard");
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [data, setData] = useState({
    active: 0,
    in_progress: 0,
    on_hold: 0,
    submitted: 0,
    total: 0,
  });
  const [activeDataa, setActiveData] = useState([]);
  const [rfpType, setRfptype] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(true);

  const storedData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    const response = localStorage.getItem("AlertPopupResponse");

    if (response === null) {
      setAlertModalShow(true);
    } else {
      setAlertModalShow(false);
    }
  }, []);

  //get activeRfp

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const refreshtoken = async () => {
    const userId = storedData.userDetails.id;
    try {
      const data = {
        userid: userId,
      };
      console.log("refresh body:", data);

      const response = await axios({
        method: "GET",
        url: "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/refreshtoken",
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLogout = () => {
    const storedData = JSON.parse(localStorage.getItem('userDetails'));
    if (storedData && storedData.userDetails) {
      const userId = storedData.userDetails.id;
      const accessToken = storedData.access_token;
      if (userId && accessToken) {
        axios.post(
          'https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/logout',
          { userid: userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((response) => {
            if (response.status === 200) {
              localStorage.removeItem('userDetails');
              localStorage.removeItem('AlertPopupResponse');
              console.log('Logout successful');
              navigate('/');
            } else {
              console.error('Logout failed');
            }
          })
          .catch((error) => {
            console.error('Error during logout:', error);
          });
      } else {
        console.error('User ID or access token not found in userDetails');
      }
    } else {
      console.error('No userDetails found in localStorage');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const storedData = JSON.parse(localStorage.getItem("userDetails"));
        const accessToken = storedData.access_token;
        const getStatus = (activeTable) => {
          switch (activeTable) {
            case "ActiveCard":
              return 1;
            case "InProgressData":
              return 2;
            case "OnHoldCard":
              return 3;
            case "SubmitCard":
              return 4;
            default:
              return 0;
          }
        };

        const status = getStatus(activeTable);
        const params = new URLSearchParams({
          search: searchTerm?.length > 3 ? searchTerm : "",
          fromDate: dateRange[0] || "",
          toDate: dateRange[1] || "",
          rfpType: rfpType || "",
          dueDate: selectedDueDate || "",
          page: 0,
          pageSize: 20,
          status: status || "",
        });
        const url = `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/activerfp?${params.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log(response.data, "responseData");
        setActiveData(response.data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.status === 401) {
          refreshtoken();
          handleLogout();
        }
        // if (error.response && error.response.status === 500) {
        //   navigate('/');
        // }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [rfpType, dateRange, activeTable, selectedDueDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));

    console.log(storedData);

    if (storedData && storedData.userDetails && storedData.userDetails.id) {
      const userId = storedData.userDetails.id;
      console.log(userId, "iddd");
      if (userId) {
        axios
          .post(
            "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/activitiesoverview",
            { userid: userId }
          )
          .then((response) => setData(response.data))
          .catch((error) => console.error("Error fetching data:", error));
      } else {
        console.error("User ID not found in userDetails");
      }
    } else {
      console.error("No userDetails found in localStorage or id is missing");
    }
  }, []);

  const handlemodalClose = () => setAlertModalShow(false);

  const handlePopupResponse = (response) => {
    localStorage.setItem("AlertPopupResponse", response);
    setAlertModalShow(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // cleanUp();
    setOpen(false);
  };

  const handleApplyRfptype = (event) => {
    setRfptype(event.target.value);
  };
  const handleClearRfpType = () => {
    setRfptype("");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterModalOpenChange = () => {
    setFilterModalOpen(true);
  };

  const handleFilterModalCloseChange = () => {
    setFilterModalOpen(false);
  };

  const handleDueDateChange = (event) => {
    setSelectedDueDate(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  // Function to clear the due date selection
  const handleClearDueDate = () => {
    setSelectedDueDate("");
  };

  const handleClearDateRange = () => {
    setDateRange([]);
  };

  return (
    <Container
      fluid
      style={{
        paddingLeft: 10,
        paddingRight: 0,
        position: "relative",
        bottom: "26px",
      }}
    >
      <Row style={{ margin: 11 }}>
        <Paper className="mt-4 pb-4 rounded-4 " style={{ width: "100%" }}>
          <div>
            <Typography className="fontstyleeb fs-6 px-2 mt-2">
              Overview
            </Typography>
          </div>
          <Container fluid>
            <Row className="g-4 pt-2 ">
              <Col lg={3} md={6} sm={12} className="TotalRpf">
                <Card
                  sx={{
                    display: "flex",
                    p: { xs: 0 },
                    border: "1px solid rgba(215, 233, 245)",
                    borderRadius: 3,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <div
                        className="pl-3 d-flex justify-content-center align-items-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "rgba(215, 233, 245)",
                          borderRadius: "10px",
                        }}
                      >
                        {/* Render your icon */}
                        <img
                          src={documentSigned}
                          alt="documentSigned"
                          width={"32px"}
                        />
                      </div>
                    </CardContent>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: { lg: 2, xs: 1 },
                    }}
                  >
                    <Typography className="fontstylee">
                      Active RFP <br />{" "}
                      <span className="text-primary fw-bold fs-4">
                        {data.active}/{data.total}
                      </span>
                    </Typography>
                  </Box>
                </Card>
              </Col>

              <Col lg={3} md={6} sm={12} className="Submitted">
                <Card
                  sx={{
                    display: "flex",
                    p: { xs: 0 },
                    border: "1px solid rgba(215, 233, 245)",
                    borderRadius: 3,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <div
                        className=" pl-3 d-flex justify-content-center align-items-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "rgba(215, 233, 245)",
                          borderRadius: "10px",
                        }}
                      >
                        {/* Render your icon */}
                        <img
                          src={SubmittedMemoCircleCheckImage}
                          alt="memoCircleCheck"
                          width={"32px"}
                        />
                      </div>
                    </CardContent>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: { lg: 2, xs: 1 },
                      pb: 1,
                    }}
                  >
                    <Typography className="fontstylee">
                      Submitted
                      <br />
                      <span className="text-primary fw-bold fs-4">
                        {data.submitted}/{data.total}
                      </span>
                    </Typography>
                  </Box>
                </Card>
              </Col>

              <Col lg={3} md={6} sm={12} className="onHold">
                <Card
                  sx={{
                    display: "flex",
                    p: { xs: 0 },
                    border: "1px solid rgba(215, 233, 245)",
                    borderRadius: 3,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <div
                        className=" pl-3 d-flex justify-content-center align-items-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "rgba(215, 233, 245)",
                          borderRadius: "10px",
                        }}
                      >
                        {/* Render your icon */}
                        <img
                          src={clockThreeImage}
                          alt="clockImage"
                          width={"32px"}
                        />
                      </div>
                    </CardContent>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: { lg: 2, xs: 1 },
                      pb: 1,
                    }}
                  >
                    <Typography className="fontstylee">
                      On Hold <br />
                      <span className="fw-bold fs-4 text-primary">
                        {data.on_hold}/{data.total}
                      </span>
                    </Typography>
                  </Box>
                </Card>
              </Col>

              <Col lg={3} md={6} sm={12}>
                <Card
                  sx={{
                    display: "flex",
                    p: { xs: 0 },
                    border: "1px solid rgba(215, 233, 245)",
                    borderRadius: 3,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <div
                        className=" pl-3 d-flex justify-content-center align-items-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "rgba(215, 233, 245)",
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          src={InprogresshourglassStart}
                          alt="hourglassStart"
                          width={"32px"}
                        />
                      </div>
                    </CardContent>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography className="fontstylee">
                      In Progress
                      <br />
                      <span className="text-primary fw-bold fs-4">
                        {data.in_progress}/{data.total}
                      </span>
                    </Typography>
                  </Box>
                </Card>
              </Col>
            </Row>
          </Container>
        </Paper>

        <Paper className="rounded-4 mt-3" style={{ height: "68vh" }}>
          <div>
            <Container fluid>
              <Row className="pt-4">
                <Col xs={3} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === "ActiveCard" ? "text-primary border-bottom border-3 border-primary pb-2 borderWidth" : "text-secondary"}`}
                    onClick={() => setActiveTable("ActiveCard")}
                  >
                    Active RFP
                  </Typography>
                </Col>
                <Col xs={3} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === "SubmitCard" ? "text-primary border-bottom border-3 border-primary pb-2 borderWidth" : "text-secondary"}`}
                    onClick={() => setActiveTable("SubmitCard")}
                  >
                    Submitted
                  </Typography>
                </Col>
                <Col xs={3} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === "OnHoldCard" ? "text-primary border-bottom border-3 border-primary pb-2 borderWidth" : "text-secondary"}`}
                    onClick={() => setActiveTable("OnHoldCard")}
                  >
                    On Hold
                  </Typography>
                </Col>
                <Col xs={3} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === "InProgressData" ? "text-primary border-bottom border-3 border-primary pb-2 borderWidth" : "text-secondary"}`}
                    onClick={() => setActiveTable("InProgressData")}
                  >
                    In-Progress
                  </Typography>
                </Col>
              </Row>

              <Divider className="mb-4 border-secondary" />

              <Row
                className="selects"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col xs={12} sm={6} md={2}>
                  <Stack direction="column" alignItems="flex-start">
                    <DateRangePicker
                      showHeader={false}
                      caretAs={KeyboardArrowDownIcon}
                      ranges={predefinedRanges}
                      placeholder="Select date"
                      className="fontstyleet"
                      size="md"
                      style={{ width: "100%" }}
                      cleanable
                      onClean={handleClearDateRange}
                      onChange={(value) => {
                        if (value && value.length === 2) {
                          const [startDate, endDate] = value;
                          const formattedStartDate = formatDate(startDate);
                          const formattedEndDate = formatDate(endDate);

                          setDateRange([formattedStartDate, formattedEndDate]);
                        }
                      }}
                      onShortcutClick={(shortcut, event) => {
                        console.log(shortcut);
                      }}
                    />
                  </Stack>
                </Col>

                <Col xs={12} sm={6} md={2} className="inputfieldsCol">
                  <FormControl fullWidth size="small" className="inputfields">
                    <InputLabel
                      sx={{ fontSize: "12px" }}
                      className="fontstyleet"
                      id="select-label"
                    >
                      RFP Type
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      className="inputfields rounded-3"
                      value={rfpType !== undefined ? rfpType : ""}
                      onChange={handleApplyRfptype}
                      label="Select RFP Type"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "6px",
                            borderRadius: "10px",
                          },
                        },
                      }}
                      IconComponent={rfpType ? () => null : undefined}
                      endAdornment={
                        rfpType && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={handleClearRfpType}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      <MenuItem value="solutioning">Solutioning</MenuItem>
                      <MenuItem value="staffing">Staffing</MenuItem>
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} sm={6} md={3} className="inputfieldsCol">
                  <TextField
                    fullWidth
                    placeholder="Search by RFP Name/Type"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="fontstyleet inputfields mx-3"
                    sx={{
                      width: "100%",
                      "@media (min-width: 769px) and (max-width: 1440px)": {
                        minWidth: "240px",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        fontSize: "12px", // Decrease the placeholder font size
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "9px",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ opacity: "40%" }} />
                        </InputAdornment>
                      ),
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={handleClearSearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  md={2}
                  className="inputfieldsCol due-date-col"
                >
                  <FormControl fullWidth size="small" className="inputfields">
                    <InputLabel sx={{ fontSize: "12px" }} id="select-label">
                      Due Date
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      className="inputfields rounded-3"
                      value={
                        selectedDueDate !== undefined ? selectedDueDate : ""
                      }
                      onChange={handleDueDateChange}
                      label="Select Date"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Decrease the placeholder font size
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "16px",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "6px",
                            borderRadius: "10px",
                          },
                        },
                      }}
                      IconComponent={selectedDueDate ? () => null : undefined}
                      endAdornment={
                        selectedDueDate && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={handleClearDueDate}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      <MenuItem value={"WITHIN_A_WEEK"}>Within a Week</MenuItem>
                      <MenuItem value={"WITHIN_10_DAYS"}>
                        Within 10 Days
                      </MenuItem>
                      <MenuItem value={"WITHIN_2_WEEKS"}>
                        Within 2 Weeks
                      </MenuItem>
                      <MenuItem value={"WITHIN_A_MONTH"}>
                        Within a Month
                      </MenuItem>
                      <MenuItem value={"WITHIN_2_MONTHS"}>
                        Within 2 Months
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} sm={8} md={1} className="inputfieldsCol ps-lg-5">
                  <Button
                    variant="outlined"
                    sx={{ width: "100%", maxWidth: "200px" }}
                    className="filterIcon border border-primary rounded-2"
                  >
                    <FilterAltOutlinedIcon
                      onClick={handleFilterModalOpenChange}
                      className="text-primary"
                    />
                  </Button>
                </Col>

                <Col xs={12} sm={6} md={2}>
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    sx={{ width: "100%", maxWidth: "200px" }}
                    className="bg-primary text-capitalize rounded-2"
                  >
                    Add RFP
                  </Button>
                </Col>
              </Row>
            </Container>

            {/* Pass the state and handlers as props to the table component */}
            <Container
              fluid
              className="mt-2 mb-1"
              style={{ padding: 0, width: "100%" }}
            >
              {activeTable === "ActiveCard" && (
                <ActiveData
                  activeDataa={activeDataa}
                  searchTerm={searchTerm}
                  dateRange={dateRange}
                  rfpType={rfpType}
                  selctedDueDate={selectedDueDate}
                  loading={loading}
                />
              )}
              {activeTable === "SubmitCard" && (
                <SubmittedData
                  activeDataa={activeDataa}
                  searchTerm={searchTerm}
                  rfpType={rfpType}
                  selctedDueDate={selectedDueDate}
                />
              )}
              {activeTable === "OnHoldCard" && (
                <OnHoldData
                  activeDataa={activeDataa}
                  searchTerm={searchTerm}
                  rfpType={rfpType}
                  selctedDueDate={selectedDueDate}
                />
              )}
              {activeTable === "InProgressData" && (
                <InProgresData
                  activeDataa={activeDataa}
                  searchTerm={searchTerm}
                  rfpType={rfpType}
                  selctedDueDate={selectedDueDate}
                />
              )}
            </Container>

            <FileUpload open={open} onClose={handleClose} />

            <Container fluid>
              <Modal
                open={filterModalOpen}
                onClose={handleFilterModalCloseChange}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%", // Adjusted width to fit well in different screen sizes
                    maxWidth: "800px", // Optional: set a max width for large screens
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2,
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      sx={{ color: "#020f00" }}
                    >
                      Filters
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleFilterModalCloseChange}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Divider className="my-1" sx={{ opacity: "0.6" }} />
                  <Typography
                    id="modal-modal-description"
                    className="mb-2"
                    sx={{ fontWeight: "500" }}
                    variant="h5"
                  >
                    Select columns to filter the data
                  </Typography>
                  <Row>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="First Review"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="RFP Issuer"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="RFP Type"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Conference Date"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Conference details"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Submission Mode"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Location"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="POC Name"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="POC Email"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Questionnaire Date"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Budget Range"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Expected Timeline"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Proposal Due"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Status"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Remarks"
                      />
                    </Col>
                    <Col lg={3} sm={6}>
                      <FormControlLabel
                        control={<Checkbox className="text-primary" />}
                        label="Website"
                      />
                    </Col>
                  </Row>
                  <Typography
                    id="modal-modal-description"
                    sx={{ color: "#020f00" }}
                    className="mt-3"
                    variant="h5"
                  >
                    Sort Data by
                  </Typography>
                  <RadioGroup>
                    <Row>
                      <Col lg={3}>
                        <FormControlLabel
                          value="Sort by A-Z"
                          control={<Radio className="text-primary" />}
                          label="Sort By A-Z"
                        />
                      </Col>
                      <Col lg={3}>
                        <FormControlLabel
                          value="Sort by Z-A"
                          control={<Radio className="text-primary" />}
                          label="Sort By Z-A"
                        />
                      </Col>
                      <Col lg={3}>
                        <FormControlLabel
                          value="Newest First"
                          control={<Radio className="text-primary" />}
                          label="Newest First"
                        />
                      </Col>
                      <Col lg={3}>
                        <FormControlLabel
                          value="Oldest First"
                          control={<Radio className="text-primary" />}
                          label="Oldest First"
                        />
                      </Col>
                    </Row>
                  </RadioGroup>
                  <Divider sx={{ my: 3, opacity: "0.6" }} />
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                      size="small"
                      onClick={handleFilterModalCloseChange}
                      className="border-primary text-primary rounded-2"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="bg-primary rounded-2"
                    >
                      Apply Filters
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Container>
          </div>

          <Modal
            open={AlertModalShow}
            onClose={handlemodalClose}
            aria-labelledby="alert-card-title"
            aria-describedby="alert-card-description"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh", // full viewport height
                // backgroundColor: '#f0f0f0', // optional background color
              }}
            >
              <Card
                sx={{
                  maxWidth: 550,
                  position: "relative",
                  padding: "16px",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="h4"
                  className="fontstyleeb ps-2"
                  component="div"
                >
                  Alert
                </Typography>
                <Divider style={{ margin: "10px 0", borderColor: "#adadad" }} />
                <IconButton
                  onClick={handlemodalClose}
                  style={{ position: "absolute", right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
                <CardContent style={{ textAlign: "center", p: 5 }}>
                  <img src={AlertError} alt="ALERTS" />
                  <Typography variant="h4" className="mt-3 fontstyleeb">
                    Preproposal Conference for Microsoft RFP is at 2:00 PM
                    today. Are you attending?
                  </Typography>
                </CardContent>
                <Divider style={{ margin: "10px 0", borderColor: "#adadad" }} />
                <CardActions style={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="outlined"
                    className="border border-primary text-primary rounded-4 px-5 p-2"
                    onClick={() => handlePopupResponse("absent")}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    className="mx-2 bg-primary text-white rounded-4  px-5 p-2"
                    onClick={() => handlePopupResponse("present")}
                  >
                    Yes
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Modal>
        </Paper>
      </Row>
    </Container>
  );
}

export default Dashboard;
