import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, Box, CircularProgress } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, Select } from '@mui/material';

const headers = [
  'Alerts', 'RFP Issuer', 'Conference Due', 'Conference Details', 'Questionnaire Date', 'Submission Date', 'First Review', 'RFP Type', 'Submission Mode', 'Location', 'POC Name', 'POC Mail', 'Budget Range(INR)', 'Expected TimeLine', 'Status', 'Website', 'Comments'
];

const statusLabels = {
  '2': <Chip label="In-progress" size="small" className="inProgress  border  rounded-1 w-100 " />
};

const icons = {
  '01': <AccessTimeFilledIcon className='text-danger' fontSize='1px'></AccessTimeFilledIcon>,
  '02': <AccessTimeFilledIcon className='text-success' fontSize='1px'></AccessTimeFilledIcon>,
  '03': <AccessTimeFilledIcon className='text-warning' fontSize='1px'></AccessTimeFilledIcon>,
};

function InProgressData({ searchTerm, activeDataa }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate data fetching
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulate a delay (e.g., for API call)
  }, [searchTerm]);

  // Filtered data based on search term
  const filteredData = activeDataa.filter((row) => {
    const matchesSearchTerm = searchTerm.length > 3 ? row.rfp_issuer.toLowerCase().includes(searchTerm.toLowerCase()) : true;
    return matchesSearchTerm;
  });

  // Calculate total rows based on filtered data
  const totalRows = filteredData.length;

  // Slice data for pagination
  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when rows per page change
  };

  const handleRowClick = (row) => {
    navigate(`/rfp-preview/${row.rfpid}`);
  };

  return (
    <div>
      <Container fluid>
        {/* Show loader if data is being fetched */}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="400px">
            <CircularProgress className=' text-primary' />
          </Box>
        ) : (
          <>
            <TableContainer
              component={Paper}
              className="mt-3"
              sx={{
                width: '100%',
                height: '380px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  height: '3px',
                  width: '3px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '6px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                  cursor: 'pointer',
                },
              }}
            >
              <Table aria-label="simple table" sx={{ minWidth: 1200 }}>
                <TableHead>
                  <TableRow>
                    {headers.map(header => (
                      <TableCell key={header} align="left" className="fontstyleeb" sx={{ width: 'auto', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="position-relative">
                  {paginatedData.length > 0 ? (
                    paginatedData.map((row) => (
                      <TableRow
                        key={row.rfpid}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Badge badgeContent={row.alerts} color="error">
                            <NotificationsIcon fontSize="medium" className='text-primary' />
                          </Badge>
                        </TableCell>
                        <TableCell align="left" className="fontstyleeb link-underline text-primary cursor-pointer" sx={{ width: 'auto', whiteSpace: 'nowrap' }} onClick={() => handleRowClick(row)}> {row.rfp_issuer}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {row.conference_date} {icons[row.alerts]} </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>{row.conference_information}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {row.questionnaire_date} {icons[row.alerts]}  </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>  {row.submission_date} {icons[row.alerts]} </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {row.first_review}  </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {row.rfp_type}  </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>{row.submission_mode}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {row.location}  </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>{row.contact_name}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>{row.contact_email}</TableCell>
                        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>{row.budget_range}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>{row.expected_timeline}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {statusLabels[row.status]} </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> <a href={row.website} target="_blank" rel="noopener noreferrer"> {row.website} </a>  </TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute', // Absolute position ensures it covers the table
                        top: 100,
                        left: 450,
                      }}
                    >
                      <Typography variant="h6">No Records Found</Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {totalRows > 10 && paginatedData.length > 0 && (
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" className="mx-1 text-secondary">
                    Rows per page:
                  </Typography>
                  <Select
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    size="small"
                    sx={{ marginRight: 2 }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </Box>
                <Pagination
                  count={Math.ceil(totalRows / rowsPerPage)}
                  page={currentPage}
                  onChange={handlePaginationChange}
                  sx={{
                    '& .Mui-selected': {
                      color: '#1976d2',
                    },
                  }}
                />
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" className="mx-1 text-secondary">
                    Go to page:
                  </Typography>
                  <TextField
                    type="text"
                    value={currentPage}
                    onChange={(e) => setCurrentPage(Number(e.target.value))}
                    variant="standard"
                    size="small"
                    sx={{ width: '60px', textAlign: 'center' }}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default InProgressData;
