
import { Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import styled from 'styled-components';


const MainAppLayout = ({ isSidebar, setIsSidebar }) => {

  const MainContent = styled.div`
  margin-top: 60px; /* Space for the topbar */
  margin-left: 250px; /* Space for the sidebar */
  padding: 10px;
  overflow-y: auto; /* Allows content to scroll */
  height: calc(100vh - 70px); /* Full height minus the topbar */
  // box-sizing: border-box;
`;
  return (
    <div style={{ display: 'flex', fontFamily: 'Poppins, sans-serif' }}>
      <Sidebar isSidebar={isSidebar} />
      <main
        style={{
          backgroundColor: "#f4f8fd",
          width: "100%",
          height: "100%",
        }}
      >
        <Topbar setIsSidebar={setIsSidebar} />
        <MainContent>
        <Outlet />
        </MainContent>
      </main>
    </div>
  );
};

export default MainAppLayout;



