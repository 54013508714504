import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Card, Typography, Button, FormControl, TextField, MenuItem, Select ,IconButton,ListSubheader} from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import PDF from '../../assets/Images/PDF.svg'
import dayjs from 'dayjs';
import axios from 'axios'



const statusOptions = [
    { value: 1, label: 'Active' },
    { value: 4, label: 'Submitted' },
    { value: 3, label: 'On_hold' },
    { value: 2, label: 'In_progress' }
];
const submissionModes = [
    { value: 'Portal', label: 'Portal' },
    { value: 'Email', label: 'Email' },
    { value: 'Physical', label: 'Physical' }
];

export default function ExtrctedPdfDocument() {
    const [errors, setErrors] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',
        comments: ''
    });
    const [formData, setFormData] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',
        comments: ''
    });
    const location = useLocation();
    const navigate = useNavigate();
    const { pdfText } = location.state;
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (pdfText) {
            setFormData({
                firstReview: pdfText.details?.first_review || '',
                rfpIssuer: pdfText.details?.rfp_issuer || '',
                rfpType: pdfText.details?.rfp_type || '',
                location: pdfText.details?.location || '',
                budgetRange: pdfText.details?.budget_range || '',
                expectedTimeline: pdfText.details?.expected_timeline || '',
                website: pdfText.details?.website || '',
                name: pdfText.details?.contact_name || '',
                email: pdfText.details?.email || '',
                conferenceDate: pdfText.details?.conference_date || '',
                conferenceInformation: pdfText.details?.conference_information || '',
                questionnaireDate: pdfText.details?.questionnaire_date || '',
                status: pdfText.details?.status || '',
                submissionMode: pdfText.details?.submission_mode || '',
                submissionDate: pdfText.details?.submission_date || '',
                // remarks: pdfText.remarks?.remarks || '',
                comments: pdfText.comments?.comments || ''
            });
        }
    }, [pdfText]);

    useEffect(() => {
        const checkFormValidity = () => {
            const allFieldsValid = Object.values(errors).every(error => !error);
            const allFieldsFilled = Object.values(formData).every(value => {
                if (typeof value === 'string') {
                    return value.trim() !== '';
                } else if (typeof value === 'number') {
                    return value !== null && value !== undefined;
                } else {
                    return value !== null && value !== undefined;
                }
            });

            setIsFormValid(allFieldsValid && allFieldsFilled);
        };

        checkFormValidity();
    }, [formData, errors]);

    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const currentDate = dayjs();

    const minDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
    const maxDate = currentDate.add(1, 'month').format('YYYY-MM-DD');
    const minDateConference = currentDate.format('YYYY-MM-DD');
    const maxDateConference = currentDate.add(1, 'month').format('YYYY-MM-DD');
    const minDateQuestionnaireDate = currentDate.format('YYYY-MM-DD');
    const maxDateQuestionnaireDate = currentDate.add(1, 'month').format('YYYY-MM-DD');
    const minDateSubmissionDate = currentDate.format('YYYY-MM-DD');
    const maxDateSubmissionDate = currentDate.add(1, 'month').format('YYYY-MM-DD');

    

    const validateField = (name, value) => {
        switch (name) {
            case 'email':
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address';
            case 'budgetRange':
                return value ? '' : 'Budget range is required';
            case 'website':
                return /^(https?:\/\/[^\s$.?#].[^\s]*)$/.test(value) ? '' : 'Invalid website URL';
            case 'conferenceInformation':
                return value ? '' : 'Conference Information is required';
            case 'submissionMode':
                return value ? '' : 'Submission mode is required';
            case 'status':
                return value ? '' : 'Status is required';
            case 'rfpIssuer':
                return value ? '' : 'RFP Issuer is required';
            case 'rfpType':
                return value ? '' : 'RFP Type is required';
            case 'location':
                return value ? '' : 'Location is required';
            case 'name':
                return value ? '' : 'Name is required';
            case 'firstReview':
            case 'expectedTimeline':
            case 'conferenceDate':
            case 'questionnaireDate':
            case 'submissionDate':
                return value ? '' : 'Date is required';
            case 'comments':
                return value ? '' : 'Comments are required';
            default:
                return '';
        }
    };
    
    
    const handleSave = async () => {
        if (!isFormValid) return;
        let hasErrors = false;
        const newErrors = {};
    
        // Validate all fields
        for (const [key, value] of Object.entries(formData)) {
            const error = validateField(key, value);
            if (error) {
                newErrors[key] = error;
                hasErrors = true;
            }
        }
    
        setErrors(newErrors);
    
        if (hasErrors) return;  // Prevent form submission if there are errors
    
        // Proceed with form submission if no errors
        const storedData = JSON.parse(localStorage.getItem('userDetails'));
        const userId = storedData?.userDetails?.id;
    
        if (!userId) {
            console.error('User ID not found in local storage');
            navigate('/404');  // Navigate to 404 if userId is missing
            return;
        }
    
        const data = {
            details: {
                userid: userId,
                first_review: formatDate(formData.firstReview),
                rfp_issuer: formData.rfpIssuer,
                rfp_type: formData.rfpType,
                location: formData.location,
                budget_range: formData.budgetRange,
                expected_timeline: formData.expectedTimeline,
                website: formData.website,
                contact_name: formData.name,
                contact_email: formData.email,
                conference_date: formatDate(formData.conferenceDate),
                conference_information: formData.conferenceInformation,
                questionnaire_date: formatDate(formData.questionnaireDate),
                submission_mode: formData.submissionMode,
                submission_date: formatDate(formData.submissionDate),
                status: formData.status,
            },
            remarks: {
                userid: userId,
                remarks: "",
            },
            comments: {
                userid: userId,
                comments: formData.comments,
            },
            filename: ''
        };
    
        axios.post('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/addrfp', data)
            .then((response) => {
                console.log('Response:', response.data);
                navigate(-1);  // Navigate to dashboard on success
            })
            .catch((error) => {
                console.error('There was an error saving the RFP:', error);
                navigate('/404');  // Navigate to 404 if there is an error
            });
    };
    
    
    
   

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        
        // Re-validate the field when the user changes it
        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };
    

    const handleRfpCancel = () => {
        navigate('/dashboard');
    };


    // const renderSubheader = (rfpType) => {
    //     if (rfpType === 'Website Redesign' || rfpType === 'Cloud' || rfpType === 'ERP' || rfpType === 'Branding' || rfpType === 'SEO & Marketing') {
    //         return 'Solutioning';
    //     }
    //     if (rfpType === 'Resource') {
    //         return 'Staffing';
    //     }
    //     return '';
    // };

    return (
        <Container fluid >
            <Card style={{borderRadius:'15px',marginLeft:'9px'}} >
            <Box  style={{ maxHeight: '88vh', overflowY: 'auto', paddingBottom: '16px' }}>
                    <div style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white', padding: '16px 0' }}>
                   
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h5">
                            <IconButton>
                            <img src={PDF} alt="pdf" width="25px" height="25px" />
                            </IconButton>
                            {pdfText.filename || 'MicrosoftRFP.pdf'}
                        </Typography>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ marginRight: 8,}}
                                className='text-primary border-1px primary border-primary rounded-2'
                                onClick={()=> navigate(-1)}
                            >
                                Back
                            </Button>
                        </Box>
                    </div>
                    <form style={{ marginLeft: '15px', width: 'calc(100% - 5%)', marginTop: '6px' }}>
                        <Container>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                   
                                    position: 'relative'
                                }}
                                className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    RFP Details
                                </Typography>
                                <Row className='pb-2'>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography >First Review</Typography>
                                            <TextField
                                                type="date"
                                                name="firstReview"
                                                value={formData.firstReview}
                                                onChange={handleInputChange}
                                                placeholder="Select date"
                                                inputProps={{
                                                    min: minDate,
                                                    max: maxDate
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                            {errors.firstReview && <Typography color="error">{errors.firstReview}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography >RFP ISSUER</Typography>
                                            <TextField
                                                placeholder="Issuer Name"
                                                name="rfpIssuer"
                                                onChange={handleInputChange}
                                                value={formData.rfpIssuer}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                             {errors.rfpIssuer && <Typography color="error">{errors.rfpIssuer}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Rfp Type</Typography>
                                            <Select value={formData.rfpType} onChange={handleInputChange} name="rfpType" sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' }, '& .MuiSelect-icon': { top: '8px', color: 'blue' }, '& .MuiOutlinedInput-input': { paddingTop: '9px' } }}>
                                                <ListSubheader className='subheader'>Solutioning</ListSubheader>
                                                <MenuItem className='menuItem' value="Web Design">Web Design</MenuItem>
                                                <MenuItem className='menuItem' value="Cloud">Cloud</MenuItem>
                                                <MenuItem className='menuItem' value="ERP">ERP</MenuItem>
                                                <MenuItem className='menuItem' value="Branding">Branding</MenuItem>
                                                <MenuItem className='menuItem' value="SEO & Marketing">SEO & Marketing</MenuItem>

                                                {/* Staffing Section */}
                                                <ListSubheader className='subheader'>Staffing</ListSubheader>
                                                <MenuItem className='menuItem' value="Resource">Resource</MenuItem>
                                            </Select>
                                            {errors.rfpType && <Typography color="error">{errors.rfpType}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Location</Typography>
                                            <TextField
                                                placeholder="Location"
                                                name="location"
                                                value={formData.location}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                            {errors.location && <Typography color="error">{errors.location}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Budget Range</Typography>
                                            <TextField
                                                placeholder="Budget Range"
                                                name="budgetRange"
                                                value={formData.budgetRange}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                            {errors.budgetRange && <Typography color="error">{errors.budgetRange}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Expected Timeline</Typography>
                                            <TextField
                                                placeholder="Expected Timeline"
                                                name="expectedTimeline"
                                                value={formData.expectedTimeline}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                             {errors.expectedTimeline && <Typography color="error">{errors.expectedTimeline}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Website</Typography>
                                            <TextField
                                                placeholder="Website"
                                                name="website"
                                                value={formData.website}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                             {errors.website && <Typography color="error">{errors.website}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                  
                                    position: 'relative'
                                }}
                                 className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Point of Contact Details
                                </Typography>
                                <Row>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Name</Typography>
                                            <TextField
                                                placeholder="Name"
                                                name="name"
                                                value={formData.name}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                               {errors.name && <Typography color="error">{errors.name}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Email</Typography>
                                            <TextField
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                              {errors.email && <Typography color="error">{errors.email}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                   
                                    position: 'relative'
                                }}
                                 className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Submission Details
                                </Typography>
                                <Row className='pb-2'>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Conference Date</Typography>
                                            <TextField
                                                type="date"
                                                name="conferenceDate"
                                                value={formData.conferenceDate}
                                                onChange={handleInputChange}
                                                placeholder="Select date"
                                                inputProps={{
                                                    min: minDateConference,
                                                    max: maxDateConference
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                              {errors.conferenceDate && <Typography color="error">{errors.conferenceDate}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Conference Information</Typography>
                                            <TextField
                                                placeholder="Conference Information"
                                                name="conferenceInformation"
                                                value={formData.conferenceInformation}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                             {errors.conferenceInformation && (
                                                <Typography color="error" sx={{ fontSize: '0.75rem' }}>
                                                    {errors.conferenceInformation}
                                                </Typography>
                                            )}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Questionnaire Date</Typography>
                                            <TextField
                                                type="date"
                                                name="questionnaireDate"
                                                value={formData.questionnaireDate}
                                                onChange={handleInputChange}
                                                placeholder="Select date"
                                                inputProps={{
                                                    min: minDateQuestionnaireDate,
                                                    max: maxDateQuestionnaireDate
                                                }} 
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                             {errors.questionnaireDate && <Typography color="error">{errors.questionnaireDate}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Status</Typography>
                                            <Select
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                name="status"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: 'blue' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {statusOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.status && <Typography color="error">{errors.status}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                <FormControl fullWidth margin="normal">
                                            <Typography>Submission Mode</Typography>
                                            <Select
                                                value={formData.submissionMode}
                                                onChange={handleInputChange}
                                                name="submissionMode"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: 'blue' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {submissionModes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.submissionMode && <Typography color="error">{errors.submissionMode}</Typography>}
                                        </FormControl>
                                        </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Submission Date</Typography>
                                            <TextField
                                                type="date"
                                                name="submissionDate"
                                                value={formData.submissionDate}
                                                onChange={handleInputChange}
                                                placeholder="Select date"
                                                inputProps={{
                                                    min: minDateSubmissionDate,
                                                    max: maxDateSubmissionDate
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                             {errors.submissionDate && <Typography color="error">{errors.submissionDate}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                 
                                    position: 'relative'
                                }}
                               
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Additional Details
                                </Typography>
                                <Row>
                                    {/* <Col lg={5}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Remarks</Typography>
                                            <TextField
                                                placeholder="Remarks"
                                                name="remarks"
                                                value={formData.remarks}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                        {errors.remarks && <Typography color="error">{errors.remarks}</Typography>}
                                        </FormControl>
                                    </Col> */}
                                    <Col lg={5}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Comments</Typography>
                                            <TextField
                                                placeholder="Comments"
                                                name="comments"
                                                value={formData.comments}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                           {errors.comments && <Typography color="error">{errors.comments}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Row className="d-flex justify-content-center">
                                <Col lg={3}></Col>
                                <Col lg={9}>
                                    <Box display="flex" justifyContent="end">
                                        <Button variant="contained" sx={{ marginTop: 2, marginRight: 2 }} size="small" disabled={!isFormValid}  onClick={handleSave}  className={` text-capitalize rounded-3 ${isFormValid ? 'button-enabled' : 'button-disabled'}`} >Save</Button>
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    </form>
                </Box>
            </Card>
        </Container>
    );
}