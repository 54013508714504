import React, { useState } from 'react';
import { MenuItem, Box, IconButton, FormControl, Select, Card, CardContent, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Container from 'react-bootstrap/Container';
import './VisualReports.css'

const yearlyData2023 = [
    { name: 'Jan', "Number Of Rfp Per Month": 25 },
    { name: 'Feb', "Number Of Rfp Per Month": 30 },
    { name: 'Mar', "Number Of Rfp Per Month": 20 },
    { name: 'Apr', "Number Of Rfp Per Month": 25 },
    { name: 'May', "Number Of Rfp Per Month": 20 },
    { name: 'Jun', "Number Of Rfp Per Month": 15 },
    { name: 'Jul', "Number Of Rfp Per Month": 25 },
    { name: 'Aug', "Number Of Rfp Per Month": 10 },
    { name: 'Sep', "Number Of Rfp Per Month": 25 },
    { name: 'Oct', "Number Of Rfp Per Month": 20 },
    { name: 'Nov', "Number Of Rfp Per Month": 25 },
    { name: 'Dec', "Number Of Rfp Per Month": 20 }
];

const yearlyData2024 = [
    { name: 'Jan', "Number Of Rfp Per Month": 25 },
    { name: 'Feb', "Number Of Rfp Per Month": 30 },
    { name: 'Mar', "Number Of Rfp Per Month": 20 },
    { name: 'Apr', "Number Of Rfp Per Month": 25 },
    { name: 'May', "Number Of Rfp Per Month": 20 },
    { name: 'Jun', "Number Of Rfp Per Month": 15 },
    { name: 'Jul', "Number Of Rfp Per Month": 0 },
    { name: 'Aug', "Number Of Rfp Per Month": 0 },
    { name: 'Sep', "Number Of Rfp Per Month": 0 },
    { name: 'Oct', "Number Of Rfp Per Month": 0 },
    { name: 'Nov', "Number Of Rfp Per Month": 0 },
    { name: 'Dec', "Number Of Rfp Per Month": 0 }
];
const rfpStatusData2023 = [
    { name: 'Submitted', value: 38, color: '#16A249' },
    { name: 'Active', value: 30, color: '#0075FF' },
    { name: 'In-Progress', value: 8, color: '#EF4343' },
    { name: 'On-Hold', value: 15, color: '#F3982E' }


];

const rfpStatusData2024 = [
    { name: 'Submitted', value: 78, color: '#16A249' },
    { name: 'Active', value: 22, color: '#0075FF' },
    { name: 'On-Hold', value: 5, color: '#F3982E' },
    { name: 'In-Progress', value: 3, color: '#EF4343' }
];


const rfpTypeData2023 = [
    { name: 'Web Design', value: 28, color: '#1F4690' },
    { name: 'Development', value: 38, color: '#16A249' },
    { name: 'Staffing', value: 35, color: '#F3982E' },
    { name: 'SEO', value: 8, color: '#EF4343' },
    { name: 'Marketing', value: 15, color: '#0088FE' },

];

const rfpTypeData2024 = [
    { name: 'Development', value: 45, color: '#16A249' },
    { name: 'Staffing', value: 35, color: '#F3982E' },
    { name: 'SEO', value: 15, color: '#EF4343' },
    { name: 'Marketing', value: 25, color: '#0088FE' },
    { name: 'Web Design', value: 30, color: '#1F4690' }
];

const submissionModeData2023 = [
    { name: 'Email', value: 38, color: '#16A249' },
    { name: 'Letter', value: 35, color: '#F3982E' },
    { name: 'Website', value: 38, color: '#0088FE' }
];

const submissionModeData2024 = [
    { name: 'Email', value: 38, color: '#16A249' },
    { name: 'Letter', value: 38, color: '#F3982E' },
    { name: 'Website', value: 35, color: '#0088FE' }
];

const locationData2023 = [
    { name: 'USA', value: 80, color: '#F3982E' },
    { name: 'Australia', value: 65, color: '#EF4343' },
    { name: 'UK', value: 30, color: '#16A249' },
    { name: 'India', value: 120, color: '#0088FE' }
];

const locationData2024 = [
    { name: 'USA', value: 300, color: '#F3982E' },
    { name: 'Australia', value: 100, color: '#EF4343' },
    { name: 'UK', value: 50, color: '#16A249' },
    { name: 'India', value: 150, color: '#0088FE' }
];

const webSiteData = [
    { name: 'Within a Month', value: 38, color: '#16A249' },
    { name: 'Within 15 Days', value: 35, color: '#F3982E' },
    { name: 'Within a Week', value: 33, color: '#EF4343' }
];

const minYear = 2023; // Replace with your minimum allowed year
const maxYear = 2024; // Replace with your maximum allowed year

const SubmissionModeChart = () => {
    const [submissionMode, setSubmissionMode] = useState('Yearly');
    const [selectedYear, setSelectedYear] = useState(2023);
    const [yearlyData, setYearlyData] = useState(yearlyData2023);
    const [rfpStatusData, setRfpStatusData] = useState(rfpStatusData2023);

    const handleChange = (event) => {
        setSubmissionMode(event.target.value);
    };

    const handlePreviousYear = () => {
        if (selectedYear > 2023) {
            const newYear = selectedYear - 1;
            setSelectedYear(newYear);
            setYearlyData(newYear === 2023 ? yearlyData2023 : yearlyData2024);
            setRfpStatusData(newYear === 2023 ? rfpStatusData2023 : rfpStatusData2024);
        }
        if (selectedYear > minYear) {
            setSelectedYear(selectedYear - 1);
        }
    };

    const handleNextYear = () => {
        if (selectedYear < 2024) {
            const newYear = selectedYear + 1;
            setSelectedYear(newYear);
            setYearlyData(newYear === 2024 ? yearlyData2024 : yearlyData2023);
            setRfpStatusData(newYear === 2024 ? rfpStatusData2024 : rfpStatusData2023);
        }
        if (selectedYear < maxYear) {
            setSelectedYear(selectedYear + 1);
        }
    };

    return (
        <Container fluid>
            <Card sx={{ width: '100%', height: '87vh', marginLeft: '9px', marginTop: '10px', borderRadius: '15px' }}>
                <CardContent>
                    <FormControl>
                        <Typography className='fw-bold pb-2'>Reports by</Typography>
                        <Select sx={{ width: '14rem', borderRadius: '12px' }} value={submissionMode} onChange={handleChange}>
                            <MenuItem value="Yearly">Yearly</MenuItem>
                            <MenuItem value="RFP Status">RFP Status</MenuItem>
                            <MenuItem value="RFP TYPE">RFP TYPE</MenuItem>
                            <MenuItem value="Submission Mode">Submission Mode</MenuItem>
                            <MenuItem value="Location">Location</MenuItem>
                            <MenuItem value="Proposal Due Date">Proposal Due Date</MenuItem>
                        </Select>
                    </FormControl>

                    <Card sx={{
                        borderRadius: 5,
                        marginTop: '18px',
                        width: submissionMode === 'Yearly' ? '100%' : submissionMode === 'RFP Status' ? '50%' : submissionMode === 'RFP TYPE' ? '50%' : submissionMode === 'Submission Mode' ? '50%' : submissionMode === 'Location' ? '100%' : submissionMode === 'Proposal Due Date' ? '50%' : '100%'
                    }} >
                        <CardContent sx={{
                            marginBottom: 7,
                            height: submissionMode === 'Yearly' ? 280 : submissionMode === 'Submission Mode' ? 450 : submissionMode === 'Location' ? 350 : submissionMode === 'RFP Status' ? 390 : submissionMode === 'RFP TYPE' ? 450 : 400,
                            width: submissionMode === 'Yearly' ? '100%' : submissionMode === 'RFP Status' ? '100%' : submissionMode === 'RFP TYPE' ? '100%' : submissionMode === 'Submission Mode' ? '100%' : submissionMode === 'Location' ? '100%' : submissionMode === 'Proposal Due Date' ? '100%' : '100%'
                        }}>
                            <Box display="flex" alignItems="baseline" sx={{ justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography variant="h6" component="div" style={{ margin: '20px 0', fontWeight: 'bold' }}>
                                        {submissionMode === 'Yearly' ? 'Total RFP Overview by year' : submissionMode === 'Submission Mode' ? 'Reports by Submission mode' : submissionMode === 'Location' ? 'Total RFP Over by Location' : submissionMode === 'RFP Status' ? 'Reports by Status' : submissionMode === 'RFP TYPE' ? 'Reports by Types' : submissionMode === 'Proposal Due Date' ? 'Reports by Due date' : submissionMode}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" sx={{ justifyContent: 'right' }}>
                                    <IconButton
                                        onClick={handlePreviousYear}
                                        disabled={selectedYear <= minYear} // Replace minYear with the minimum allowed year
                                    >
                                        <ArrowBackIosIcon sx={{ fontSize: '12px' }} />
                                    </IconButton>
                                    <Typography variant="body2" color="textSecondary">
                                        Year {selectedYear}
                                    </Typography>
                                    <IconButton
                                        onClick={handleNextYear}
                                        disabled={selectedYear >= maxYear} // Replace maxYear with the maximum allowed year
                                    >
                                        <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
                                    </IconButton>
                                </Box>

                            </Box>
                            {submissionMode === 'Yearly' && (
                                <ResponsiveContainer width="100%" height={250}>
                                    <BarChart data={yearlyData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="0" vertical={false} />
                                        <XAxis dataKey="name" />
                                        <YAxis ticks={[5, 15, 20, 25, 30]} style={{ margin: '20px 19px' }} tickLine={false} axisLine={false} />
                                        <Tooltip
                                            cursor={{ fill: 'none' }}
                                            contentStyle={{
                                                border: '1px solid var(--bs-primary)',
                                                background: '#fff',
                                                padding: '3px',
                                                fontSize: '10px',
                                                borderRadius: '8px'
                                            }}
                                            labelStyle={{ display: 'none' }}
                                            formatter={(value) => [`${value} RFP`]}
                                        />
                                        <Legend


                                        />
                                        <Bar dataKey="Number Of Rfp Per Month" fill="#0075FF" barSize={12} radius={[10, 10, 0, 0]} />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}

                            {submissionMode === 'RFP Status' && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
                                    <ResponsiveContainer width="70%" height={350}>
                                        <PieChart>
                                            <Pie
                                                data={rfpStatusData}
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={75}
                                                outerRadius={135}
                                                paddingAngle={0}
                                                dataKey="value"
                                                stroke="none"
                                            >
                                                {/* <Legend/> */}
                                                <Cell key="Submitted" fill="#16A249" />

                                                <Cell key="On-Hold" fill="#F3982E" />
                                                <Cell key="In-Progress" fill="#EF4343" />
                                                <Cell key="Active" fill="#0075FF" />



                                            </Pie>
                                            <Tooltip />
                                            <Legend />
                                        </PieChart>
                                    </ResponsiveContainer>
                                    <Typography variant="h4" component="div">
                                        128
                                    </Typography>
                                </div>
                            )}

                            {submissionMode === 'RFP TYPE' && (
                                <ResponsiveContainer width="100%" height={350}>
                                    <PieChart>
                                        <Pie
                                            data={selectedYear === 2023 ? rfpTypeData2023 : rfpTypeData2024}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                            stroke='none'
                                        >
                                            {(selectedYear === 2023 ? rfpTypeData2023 : rfpTypeData2024).map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            )}

                            {submissionMode === 'Submission Mode' && (
                                <ResponsiveContainer width="100%" height={400}>
                                    <PieChart>
                                        <Pie
                                            data={selectedYear === 2023 ? submissionModeData2023 : submissionModeData2024}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {(selectedYear === 2023 ? submissionModeData2023 : submissionModeData2024).map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            )}
                            {submissionMode === 'Location' && (
                                <ResponsiveContainer width="100%" height={300} className='ps-3'>
                                    <BarChart layout="vertical" data={selectedYear === 2023 ? locationData2023 : locationData2024}>
                                        <CartesianGrid strokeDasharray="0 0" vertical={false} />
                                        <XAxis type="number" tickLine={false} axisLine={false} tick={false} />
                                        <YAxis type="category" dataKey="name" tickLine={false} axisLine={false} />
                                        <Tooltip cursor={{ fill: 'none' }} />
                                        {/* <Legend /> */}
                                        <Bar dataKey="value" fill="#8884d8" radius={[10, 10, 10, 10]} barSize={34}>
                                            {(selectedYear === 2023 ? locationData2023 : locationData2024).map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>

                            )}
                            {submissionMode === 'Proposal Due Date' && (
                                <ResponsiveContainer width="100%" height={350}>
                                    <PieChart>
                                        <Pie
                                            data={webSiteData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={135}
                                            innerRadius={75}
                                            fill="#8884d8"
                                            dataKey="value"
                                            paddingAngle={0}
                                            stroke='none'
                                        >
                                            {webSiteData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            )}

                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
        </Container>
    );
};

export default SubmissionModeChart;

