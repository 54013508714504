import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Drawer,
  Divider,
  InputAdornment,
  FormControl,
} from "@mui/material";
import { Save, Delete } from "@mui/icons-material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useSnackbar } from "../SnackbarContext";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 4,
};

function PromptConfiguration() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [editTagId, setEditTagId] = useState(null);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [editTagValue, setEditTagValue] = useState("");
  const [editQuestionValue, setEditQuestionValue] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState(null);
  const [newPrompt, setNewPrompt] = useState({
    tagName: "",
    prompt_description: "",
  });
  const [editId, setEditId] = useState(null);
  const { showSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({ tagName: "", prompt_description: "" });
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    if (storedData && storedData.userDetails) {
      const userId = storedData.userDetails.id;
      const accessToken = storedData.access_token;
      if (userId && accessToken) {
        axios
          .post(
            "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/logout",
            { userid: userId },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              localStorage.removeItem("userDetails");
              localStorage.removeItem("AlertPopupResponse");
              console.log("Logout successful");
              navigate("/");
            } else {
              console.error("Logout failed");
            }
          })
          .catch((error) => {
            console.error("Error during logout:", error);
          });
      } else {
        console.error("User ID or access token not found in userDetails");
      }
    } else {
      console.error("No userDetails found in localStorage");
    }
  };
  const fetchPrompts = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const accessToken = storedData.access_token;
    const userId = storedData.id;

    const search = "";
    const page = 0;
    const pageSize = 10;
    const url = `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/prompts_list?search=${encodeURIComponent(search)}&page=${page}&pageSize=${pageSize}`;

    try {
      setLoading(true);
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.status_code === 200) {
        setPrompts(response.data.data);
        setEditId(response.data.data.id);
        console.log(response.data.data, "promptsData");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
        // try {
        //   const refreshResponse = await axios.get(
        //     'https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/refreshtoken',
        //     {
        //       userid: userId
        //     }

        //   );
        //   console.log(refreshResponse, 'refreshtoken')

        //   if (refreshResponse.data.status_code === 200) {
        //     storedData.access_token = refreshResponse.data.access_token;
        //     localStorage.setItem('userDetails', JSON.stringify(storedData));
        //     window.location.reload()
        //     const response = await axios.get(url, {
        //       headers: {
        //         Authorization: `Bearer ${refreshResponse.data.access_token}`,
        //       },
        //     });

        //     if (response.data.status_code === 200) {
        //       setPrompts(response.data.data);
        //       setEditId(response.data.data.id);
        //       console.log(response.data.data, 'promptsData');
        //     }
        //   }
        // } catch (refreshError) {
        //   console.error('Error refreshing token:', refreshError);
        // }
      } else {
        console.error("Error fetching prompts:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrompts();
  }, []);

  const handleDeletePrompt = async (id) => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const accessToken = storedData.access_token;

    const url =
      "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/delprompt";

    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: { id },
      });

      if (response.data.status_code === 200) {
        setPrompts(prompts.filter((prompt) => prompt.id !== id));
        setDeleteConfirmOpen(false);
        showSnackbar("Prompt deleted successfully.");
      } else {
        console.error("Failed to delete prompt:", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error(
          "Error:",
          error.response.data.msg || error.response.data.message
        );
      } else {
        console.error("Error deleting prompt:", error);
      }
    }
  };

  const handleEditTagClick = (prompt) => {
    setEditTagId(prompt.id);
    setEditTagValue(prompt.prompt_name);
  };

  const handleEditQuestionClick = (prompt) => {
    setEditQuestionId(prompt.id);
    setEditQuestionValue(prompt.prompt_description);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSaveTagClick = (id) => {
    setPrompts(
      prompts.map((prompt) =>
        prompt.id === id ? { ...prompt, prompt_name: editTagValue } : prompt
      )
    );
    setEditTagId(null);
  };

  const handleSaveQuestionClick = (id) => {
    setPrompts(
      prompts.map((prompt) =>
        prompt.id === id
          ? { ...prompt, prompt_description: editQuestionValue }
          : prompt
      )
    );
    setEditQuestionId(null);
  };

  const handleDeleteIconClick = (prompt) => {
    setPromptToDelete(prompt);
    setDeleteConfirmOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteConfirmOpen(false);
    setPromptToDelete(null);
  };

  const filteredPrompts =
    searchTerm.length > 3
      ? prompts.filter(
          (prompt) =>
            prompt.prompt_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            prompt.prompt_description
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      : prompts;

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleInputChange = (e) => {
    setNewPrompt({ ...newPrompt, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    let errors = { tagName: "", prompt_description: "" };

    if (!newPrompt.tagName.trim()) {
      errors.tagName = "Tag Name is required";
      isValid = false;
    }

    if (!newPrompt.prompt_description.trim()) {
      errors.prompt_description = "Question is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/addprompt",
        {
          prompt_name: newPrompt.tagName,
          prompt_description: newPrompt.prompt_description,
        }
      );

      if (response.data.status_code === 200) {
        showSnackbar("Prompt added successfully!");
        setNewPrompt({ tagName: "", prompt_description: "" });
        fetchPrompts();
        setDrawerOpen(false);
      } else {
        showSnackbar("Failed to add prompt.", "error");
      }
    } catch (error) {
      console.error("Error adding prompt:", error);
      showSnackbar("Error adding prompt. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePrompts = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const accessToken = storedData.access_token;

    try {
      setLoading(true);
      for (const prompt of prompts) {
        const promptToUpdate = {
          id: prompt.id,
          prompt_name: prompt.prompt_name,
          prompt_description: prompt.prompt_description,
        };

        const response = await axios.put(
          "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/updateprompt",
          promptToUpdate,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.status_code === 200) {
          console.log(`Prompt ${prompt.id} updated successfully`);
        } else {
          console.error(
            `Failed to update prompt ${prompt.id}:`,
            response.data.message
          );
        }
      }

      showSnackbar("Prompts updated successfully!");
    } catch (error) {
      console.error("Error updating prompts:", error);
      showSnackbar("Error updating prompts. Please try again.", "error"); // Show error Snackbar on exception
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      fluid
      maxWidth="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "white",
          flexGrow: 1,
          boxShadow: 10,
          mt: 2,
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Prompt Configuration
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder="Search by RFP Name"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              sx={{
                marginRight: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "9px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ opacity: "40%" }} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearSearch}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              className="bg-primary text-capitalize rounded-3"
              onClick={toggleDrawer(true)}
            >
              Add Prompt
            </Button>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: 2, borderRadius: "15px" }}
        >
          <Table>
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                }}
              >
                <CircularProgress className=" text-primary" />
              </Box>
            )}
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>S.No</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Tags</TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Edit
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Questions</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Edit</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPrompts.map((prompt, index) => (
                <TableRow key={prompt.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>
                    {editTagId === prompt.id ? (
                      <TextField
                        value={editTagValue}
                        onChange={(e) => setEditTagValue(e.target.value)}
                        onBlur={() => handleSaveTagClick(prompt.id)}
                        autoFocus
                      />
                    ) : (
                      prompt.prompt_name
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    <IconButton
                      color="primary"
                      onClick={() => handleEditTagClick(prompt)}
                    >
                      {editTagId === prompt.id ? (
                        <Save
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      ) : (
                        <ModeEditOutlinedIcon
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>
                    {editQuestionId === prompt.id ? (
                      <TextField
                        value={editQuestionValue}
                        onChange={(e) => setEditQuestionValue(e.target.value)}
                        onBlur={() => handleSaveQuestionClick(prompt.id)}
                        autoFocus
                        fullWidth
                      />
                    ) : (
                      prompt.prompt_description
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditQuestionClick(prompt)}
                    >
                      {editQuestionId === prompt.id ? (
                        <Save
                          onClick={handleUpdatePrompts}
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      ) : (
                        <ModeEditOutlinedIcon
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteIconClick(prompt)}
                    >
                      <Delete
                        sx={{
                          backgroundColor: "#FFEAEA",
                          color: "#F44336",
                          padding: "3px",
                          borderRadius: "13px",
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => toggleDrawer(false)}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" className="fontstylee mx-3 my-3">
              Add New Prompt
            </Typography>
            <IconButton
              sx={{ marginRight: "7px" }}
              onClick={toggleDrawer(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider className="mx-3" style={{ borderColor: "black" }} />
          <Box p={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography gutterBottom className="fontstyleeb">
                Add Prompt Details
              </Typography>
            </Box>
            <form
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Container style={{ flexGrow: 1, width: "350px" }}>
                <Row>
                  <Col md={12}>
                    <FormControl fullWidth margin="normal">
                      <Typography className="fontstylee pb-2">
                        Tag Name
                      </Typography>
                      <TextField
                        placeholder="Tag Name"
                        name="tagName"
                        value={newPrompt.tagName}
                        onChange={handleInputChange}
                        sx={{
                          "& .MuiOutlinedInput-root": { borderRadius: "16px" },
                        }}
                        error={Boolean(errors.tagName)}
                        helperText={errors.tagName}
                      />
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormControl fullWidth margin="normal">
                      <Typography className="fontstylee pb-2">
                        Question
                      </Typography>
                      <TextField
                        placeholder="Question"
                        name="prompt_description"
                        value={newPrompt.prompt_description}
                        onChange={handleInputChange}
                        sx={{
                          "& .MuiOutlinedInput-root": { borderRadius: "16px" },
                        }}
                        multiline
                        rows={4}
                        error={Boolean(errors.prompt_description)}
                        helperText={errors.prompt_description}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Container>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "46vh",
                  padding: "16px",
                }}
              >
                <div style={{ flex: 1 }}></div>
                <Divider style={{ borderColor: "black" }} />
                <Row style={{ marginTop: "17px" }}>
                  <Col lg={{ span: 3, offset: 5 }} className="mb-2">
                    <Button
                      variant="outlined"
                      onClick={toggleDrawer(false)}
                      className="border-primary text-capitalize rounded-3"
                      sx={{ marginRight: 2, color: "#0D6EFD" }}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col lg={4}>
                    <Button
                      variant="contained"
                      className="bg-primary text-capitalize rounded-3"
                      fullWidth
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Container>
            </form>
          </Box>
        </Drawer>
        <Modal
          open={deleteConfirmOpen}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: "500px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <CloseIcon
                onClick={handleCloseDeleteDialog}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Divider style={{ borderColor: "#adadad" }} />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete?
            </Typography>
            <Box mt={4} display="flex" justifyContent="end">
              <Button
                variant="contained"
                className="bg-primary mx-2 rounded-3"
                onClick={handleCloseDeleteDialog}
              >
                No
              </Button>
              <Button
                variant="outlined"
                className="border-primary text-primary rounded-3"
                onClick={() => handleDeletePrompt(promptToDelete.id)}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box
          sx={{ display: "flex", justifyContent: "end", paddingTop: "14px" }}
        >
          <Button
            variant="contained"
            color="primary"
            className="bg-primary text-capitalize rounded-3"
            onClick={handleUpdatePrompts}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default PromptConfiguration;
