import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, Box, CircularProgress } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const headers = [
    'First Review', 'RFP Issuer', 'RFP Type', 'Conference Due', 'Conference Details', 'Submission Mode', 'Location', 'POC Name', 'POC Mail', 'Questionnaire Date', 'Budget Range(INR)', 'Expected TimeLine', 'Status', 'Website', 'Comments'
];

const statusLabels = {
    '4': <Chip label="Submitted" size="small" className='border border-success text-success rounded-1 successlabel w-100' />,
};

function SubmittedDate({ searchTerm, selectedFilters, activeDataa }) {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Initial rows per page
    const [loading, setLoading] = useState(true); // Loading state

    const totalRows = activeDataa.length; // Total data count

    useEffect(() => {
        // Simulate data fetching
        setLoading(true);
        setTimeout(() => {
            setLoading(false); // Set loading to false after data is fetched
        }, 1000); // Adjust the delay as necessary
    }, [activeDataa]);

    const filteredData = activeDataa.filter((row) => {
        const matchesSearchTerm = searchTerm.length > 3 ? row.rfp_issuer.toLowerCase().includes(searchTerm.toLowerCase()) : true;
        return matchesSearchTerm;
    });

    // Get paginated data
    const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to page 1 when rows per page changes
    };

    const handleRowClick = (row) => {
        navigate(`/rfp-preview/${row.rfpid}`);
    };

    return (
        <div>
            <Container fluid disableGutters>
                {/* Display CircularProgress when loading */}
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                        <CircularProgress className=' text-primary' />
                    </Box>
                ) : (
                    <>
                        <TableContainer
                            component={Paper}
                            className='mt-3'
                            sx={{
                                width: '100%',
                                height: '380px',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    height: '3px', // Adjust scrollbar height
                                    width: '3px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888', // Scrollbar thumb color
                                    borderRadius: '6px', // Scrollbar thumb border radius
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: '#555', // Scrollbar thumb color on hover
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            <Table aria-label="simple table" sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map(header => (
                                            <TableCell key={header} align="left" className="fontstyleeb" sx={{ width: 'auto', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="position-relative">
                                    {paginatedData.length > 0 ? (
                                        paginatedData.map((row) => (
                                            <TableRow
                                                key={row.rfpid}
                                            >
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.first_review}</TableCell>
                                                <TableCell align="left" className='fontstyleeb text-primary cursor' sx={{ width: 'auto', whiteSpace: 'nowrap' }} onClick={() => handleRowClick(row)}>{row.rfp_issuer}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.rfp_type}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.conference_date}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.conference_information}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.submission_mode}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.location}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.contact_name}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.contact_email}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.questionnaire_date}</TableCell>
                                                <TableCell align="right" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.budget_range}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.expected_timeline}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{statusLabels[row.status]}</TableCell>
                                                <TableCell align="left"><a href={row.website} target="_blank" rel="noopener noreferrer">{row.website}</a></TableCell>
                                                <TableCell align="left"></TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 100,
                                                left: 450,
                                            }}
                                        >
                                            <Typography variant="h6">No Records Found</Typography>
                                        </Box>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Pagination and Rows per Page */}
                        {totalRows > 10 && paginatedData.length > 0 && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" className="mx-2 text-secondary">
                                        Rows per page:
                                    </Typography>
                                    <Select
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                        size="small"
                                        sx={{ marginRight: 2 }}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                    </Select>
                                </Box>
                                <Pagination
                                    count={Math.ceil(filteredData.length / rowsPerPage)}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                    sx={{
                                        '& .Mui-selected': {
                                            color: '#1976d2',
                                        },
                                    }}
                                />
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" className="mx-1 text-secondary">
                                        Go to page:
                                    </Typography>
                                    <TextField
                                        type="text"
                                        value={currentPage}
                                        onChange={handleChangePage}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            sx: {
                                                width: '60px',
                                                '& input': {
                                                    textAlign: 'center',
                                                },
                                            },
                                        }}
                                        size="small"
                                        placeholder="___"
                                    />
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </Container>
        </div>
    );
}

export default SubmittedDate;
