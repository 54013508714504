import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Typography, Button, FormControl, TextField, TextareaAutosize, MenuItem, Select, ListSubheader } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from '@mui/system';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import dayjs from 'dayjs';
import { Scrollbars } from 'react-custom-scrollbars';
import './AddnewRfp.css'
import { useSnackbar } from '../SnackbarContext';


const patterns = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    url: /^(https?:\/\/[^\s/$.?#].[^\s]*)$/,
    name: /^[a-zA-Z\s]+$/,
    date: /^\d{4}-\d{2}-\d{2}$/,
    budget: /^[0-9]+(-[0-9]+)?$/,
};

const statusOptions = [
    { value: 1, label: 'Active' },
    { value: 4, label: 'Submitted' },
    { value: 3, label: 'On_hold' },
    { value: 2, label: 'In_progress' }
];
const submissionModes = [
    { value: 'portal', label: 'Portal' },
    { value: 'email', label: 'Email' },
    { value: 'physical', label: 'Physical' }
];
export default function AddNewRpfPage() {
    const [formData, setFormData] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',

        comments: ''
    });
    const [errors, setErrors] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',
        comments: ''
    });

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const validateField = (name, value) => {
        switch (name) {
            case 'email':
                return patterns.email.test(value) ? '' : 'Invalid email address';
            case 'website':
                return patterns.url.test(value) ? '' : 'Invalid website URL';
            case 'name':
                return patterns.name.test(value) ? '' : 'Name can only contain letters and spaces';
            case 'budgetRange':
                return patterns.budget.test(value) ? '' : 'Budget Range can only contain numbers';
            case 'date':
                return patterns.date.test(value) ? '' : 'Invalid date format';
            case 'rfpIssuer':
                return patterns.name.test(value) ? '' : 'RFP Issuer can only contain letters and spaces';
            default:
                return value ? '' : 'This field is required';
        }
    };

    const currentDate = dayjs();

    const minDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
    const maxDate = currentDate.add(1, 'month').format('YYYY-MM-DD');
    const minDateConference = currentDate.format('YYYY-MM-DD');
    const maxDateConference = currentDate.add(1, 'month').format('YYYY-MM-DD');
    const minDateQuestionnaireDate = currentDate.format('YYYY-MM-DD');
    const maxDateQuestionnaireDate = currentDate.add(1, 'month').format('YYYY-MM-DD');
    const minDateSubmissionDate = currentDate.format('YYYY-MM-DD');
    const maxDateSubmissionDate = currentDate.add(1, 'month').format('YYYY-MM-DD');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const error = validateField(name, value);
        if (name === 'firstReview' || name === 'expectedTimeline' || name === 'conferenceDate' || name === 'questionnaireDate' || name === 'submissionDate') {
            console.log(`${name}:`, value);
        }

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: error
        });
    };

    const [loading, setLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleRfpCancel = () => {
        navigate('/dashboard');
    };

    const SpinnerContainer = styled(Box)(({ theme }) => ({
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1300,
    }));

    useEffect(() => {
        const checkFormValidity = () => {
            const allFieldsValid = Object.values(errors).every(error => !error);
            const allFieldsFilled = Object.values(formData).every(value => {
                if (typeof value === 'string') {
                    return value.trim() !== '';
                } else if (typeof value === 'number') {
                    return value !== null && value !== undefined;
                } else {
                    return value !== null && value !== undefined;
                }
            });

            setIsFormValid(allFieldsValid && allFieldsFilled);
        };

        checkFormValidity();
    }, [formData, errors]);



    const handleSave = async () => {
        if (!isFormValid) return; // Prevent save if validation fails

        let hasErrors = false;
        const newErrors = {};
        for (const [key, value] of Object.entries(formData)) {
            const error = validateField(key, value);
            if (error) {
                newErrors[key] = error;
                hasErrors = true;
            }
        }
        setErrors(newErrors);

        if (hasErrors) return;

        setLoading(true);
        try {
            // Simulate a save operation
            await new Promise((resolve) => setTimeout(resolve, 2000));
            showSnackbar('Details added successfully', 'success');
        } catch (error) {
            showSnackbar('Failed to add details', 'error');
        } finally {
            setLoading(false);
        }


        try {
            const storedData = JSON.parse(localStorage.getItem('userDetails'));
            const userId = storedData?.userDetails?.id;

            if (!userId) {
                throw new Error('User ID not found in local storage');
            }

            const data = {
                details: {
                    userid: userId,
                    first_review: formatDate(formData.firstReview),
                    rfp_issuer: formData.rfpIssuer,
                    rfp_type: formData.rfpType,
                    location: formData.location,
                    budget_range: formData.budgetRange,
                    expected_timeline: formatDate(formData.expectedTimeline),
                    website: formData.website,
                    contact_name: formData.name,
                    contact_email: formData.email,
                    conference_date: formatDate(formData.conferenceDate),
                    conference_information: formData.conferenceInformation,
                    questionnaire_date: formatDate(formData.questionnaireDate),
                    submission_mode: formData.submissionMode,
                    submission_date: formatDate(formData.submissionDate),
                    status: formData.status,
                },
                remarks: {
                    userid: userId,
                    remarks: "",
                },
                comments: {
                    userid: userId,
                    comments: formData.comments,
                },
                filename: ''
            };
            

            const response = await axios.post('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/addrfp', data);
            console.log('Response:', response.data);
            navigate(-1);
        } catch (error) {
            console.error('There was an error saving the RFP:', error);
            navigate('/404');
        }
    };


    const handleReset = () => {
        setFormData({
            firstReview: '',
            rfpIssuer: '',
            rfpType: '',
            location: '',
            budgetRange: '',
            expectedTimeline: '',
            website: '',
            name: '',
            email: '',
            conferenceDate: '',
            conferenceInformation: '',
            questionnaireDate: '',
            status: '',
            submissionMode: '',
            submissionDate: '',
            // remarks: '',
            comments: ''
        });
        setErrors({
            firstReview: '',
            rfpIssuer: '',
            rfpType: '',
            location: '',
            budgetRange: '',
            expectedTimeline: '',
            website: '',
            name: '',
            email: '',
            conferenceDate: '',
            conferenceInformation: '',
            questionnaireDate: '',
            status: '',
            submissionMode: '',
            submissionDate: '',
            // remarks: '',
            comments: ''
        });
    };

    return (
        <Container fluid >
            <Card style={{ borderRadius: '15px', marginLeft: '9px' }}>
                <Box style={{ maxHeight: '83vh', overflowY: 'auto', paddingBottom: '16px' }}>
                    <div style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white', padding: '16px 0' }}>
                        <Typography variant="h4" gutterBottom className=' mx-3'>
                            {/* {state && state.formData ? 'Edit RFP Details' : 'Add RFP Details'} */}
                            Add RFP Details
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ marginRight: 2, marginTop: -4 }}
                                className='text-primary border-1px primary border-primary rounded-2'
                                onClick={()=> navigate(-1)}
                            >
                                Back
                            </Button>
                        </Box>
                    </div>
                    <form style={{ marginTop: '20px' }}>
                        <Container>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',

                                    position: 'relative'
                                }}
                                className='mb-5'
                            >

                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    RFP Details
                                </Typography>
                                <Row className='pb-2'>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>First Review</Typography>
                                            <TextField type="date" name="firstReview" value={formData.firstReview} onChange={handleInputChange} placeholder="Select date" inputProps={{
                                                min: minDate,
                                                max: maxDate
                                            }} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} />
                                            {errors.firstReview && <Typography color="error">{errors.firstReview}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>RFP ISSUER</Typography>
                                            <TextField placeholder="Issuer Name" name="rfpIssuer" onChange={handleInputChange} value={formData.rfpIssuer} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} />
                                            {errors.rfpIssuer && <Typography color="error">{errors.rfpIssuer}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Rfp Type</Typography>
                                            <Select value={formData.rfpType} onChange={handleInputChange} name="rfpType" sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' }, '& .MuiSelect-icon': { top: '8px', color: 'blue' }, '& .MuiOutlinedInput-input': { paddingTop: '9px' } }}>
                                                <ListSubheader className='subheader'>Solutioning</ListSubheader>
                                                <MenuItem className='menuItem' value="Web Design">Web Design</MenuItem>
                                                <MenuItem className='menuItem' value="Cloud">Cloud</MenuItem>
                                                <MenuItem className='menuItem' value="ERP">ERP</MenuItem>
                                                <MenuItem className='menuItem' value="Branding">Branding</MenuItem>
                                                <MenuItem className='menuItem' value="SEO & Marketing">SEO & Marketing</MenuItem>

                                                {/* Staffing Section */}
                                                <ListSubheader className='subheader'>Staffing</ListSubheader>
                                                <MenuItem className='menuItem' value="Resource">Resource</MenuItem>
                                            </Select>
                                            {errors.rfpType && <Typography color="error">{errors.rfpType}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Location</Typography>
                                            <TextField placeholder="Location" name="location" value={formData.location} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.location && <Typography color="error">{errors.location}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Budget Range</Typography>
                                            <TextField placeholder="Budget Range" name="budgetRange" value={formData.budgetRange} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.budgetRange && <Typography color="error">{errors.budgetRange}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Expected Timeline</Typography>
                                            <TextField placeholder="Expected Timeline" name="expectedTimeline" value={formData.expectedTimeline} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.expectedTimeline && <Typography color="error">{errors.expectedTimeline}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Website</Typography>
                                            <TextField placeholder="Website" name="website" value={formData.website} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.website && <Typography color="error">{errors.website}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    // marginBottom: '25px',
                                    position: 'relative'
                                }}
                                className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Point of Contact Details
                                </Typography>
                                <Row>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Name</Typography>
                                            <TextField placeholder="Name" name="name" value={formData.name} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.name && <Typography color="error">{errors.name}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Email</Typography>
                                            <TextField placeholder="Email" name="email" value={formData.email} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.email && <Typography color="error">{errors.email}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    marginBottom: '16px',
                                    position: 'relative'
                                }}
                                className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Submission Details
                                </Typography>
                                <Row>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Conference Date</Typography>
                                            <TextField type="date" name="conferenceDate" value={formData.conferenceDate} onChange={handleInputChange} placeholder="Select date" inputProps={{
                                                min: minDateConference,
                                                max: maxDateConference
                                            }} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} />
                                            {errors.conferenceDate && <Typography color="error">{errors.conferenceDate}</Typography>}

                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Conference Information</Typography>
                                            <div style={{
                                                width: '100%',
                                                height: '100px', // Fixed height
                                                borderRadius: '10px',
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                overflow: 'hidden',
                                            }}>
                                                <Scrollbars autoHide>
                                                    <TextField
                                                        multiline
                                                        name="conferenceInformation"
                                                        value={formData.conferenceInformation}
                                                        onChange={handleInputChange}
                                                        placeholder="Conference Information"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={{
                                                            sx: {
                                                                height: '100px', // Match the fixed height
                                                                '& textarea': {
                                                                    height: '100%',
                                                                    boxSizing: 'border-box'
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none',
                                                                },
                                                            }
                                                        }}
                                                    />
                                                </Scrollbars>
                                            </div>
                                            {errors.conferenceInformation && (
                                                <Typography color="error" sx={{ fontSize: '0.75rem' }}>
                                                    {errors.conferenceInformation}
                                                </Typography>
                                            )}
                                        </FormControl>

                                    </Col>


                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Questionnaire Date</Typography>
                                            <TextField type="date" name="questionnaireDate" value={formData.questionnaireDate} onChange={handleInputChange} placeholder="Select date" inputProps={{
                                                min: minDateQuestionnaireDate,
                                                max: maxDateQuestionnaireDate
                                            }} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} />
                                            {errors.questionnaireDate && <Typography color="error">{errors.questionnaireDate}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Status</Typography>
                                            <Select
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                name="status"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: 'blue' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {statusOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.status && <Typography color="error">{errors.status}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Submission Mode</Typography>
                                            <Select
                                                value={formData.submissionMode}
                                                onChange={handleInputChange}
                                                name="submissionMode"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: 'blue' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {submissionModes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.submissionMode && <Typography color="error">{errors.submissionMode}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Submission Date</Typography>
                                            <TextField type="date" name="submissionDate" value={formData.submissionDate} onChange={handleInputChange} placeholder="Select date" inputProps={{
                                                min: minDateSubmissionDate,
                                                max: maxDateSubmissionDate
                                            }} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} />
                                            {errors.submissionDate && <Typography color="error">{errors.submissionDate}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    marginBottom: '16px',
                                    position: 'relative'
                                }}
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Additional Details
                                </Typography>

                                <Row>
                                    {/* <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Remarks</Typography>
                                            <TextField placeholder="Remarks" name="remarks" value={formData.remarks} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.remarks && <Typography color="error">{errors.remarks}</Typography>}
                                        </FormControl>
                                    </Col> */}
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Comments</Typography>
                                            <TextField placeholder="Comments" name="comments" value={formData.comments} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.comments && <Typography color="error">{errors.comments}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Row className="d-flex justify-content-center">
                                <Col lg={3}></Col>
                                <Col lg={9}>
                                    <Box display="flex" justifyContent="end" mt={2} mx={1}>
                                        <Button variant="outlined" color="secondary" onClick={handleReset} className='mx-2 border border-primary text-primary rounded-2'>Reset</Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                            className={` text-capitalize rounded-3 ${isFormValid ? 'button-enabled' : 'button-disabled'}`}
                                            disabled={!isFormValid}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    </form>
                </Box>
            </Card>
            {loading && (
                <SpinnerContainer>
                    <ClipLoader size={50} color="#123abc" loading={loading} />
                </SpinnerContainer>
            )}
        </Container>
    );
}