import React, { useState } from 'react';
import { Box, Button, Alert, AlertTitle, Container, Typography, Badge, Divider,Modal } from '@mui/material';
import Danger from '../../assets/Images/Danger.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import './Alerts_Notification.css';

const notifications = [
  { type: 'Conference Due', text: 'Preproposal Conference due Date is Near for RFP Microsoft', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '10 Min' },
  { type: 'Conference Due', text: 'Preproposal Conference due Date is Near for RFP Flipkart', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '30 Min' },
  { type: 'Conference Due', text: 'Preproposal Conference due Date is Near for RFP Microsoft', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '1 Hr' },
  { type: 'Conference Due', text: 'Preproposal Conference due Date is Near for RFP Google', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: 'Yesterday' },
  { type: 'Conference Due', text: 'Preproposal Conference due Date is Near for RFP Amazon', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: 'Yesterday' },
  { type: 'Conference Due', text: 'Preproposal Conference due Date is Near for RFP Meta', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '2 days ago' },
  { type: 'Conference Due', text: 'Preproposal Conference due Date is Near for RFP Meta', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '2 days ago' },
  { type: 'RFP Questions Due', text: 'RFP Questions Due Date is Near for RFP Microsoft', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '10 Min' },
  { type: 'RFP Questions Due', text: 'RFP Questions Due Date is Near for RFP Flipkart', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '10 Min' },
  { type: 'RFP Questions Due', text: 'RFP Questions Due Date is Near for RFP Microsoft', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: '1 Hr' },
  { type: 'RFP Questions Due', text: 'RFP Questions Due Date is Near for RFP Google', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: 'Yesterday' },
  { type: 'RFP Questions Due', text: 'RFP Questions Due Date is Near for RFP Amazon', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time', time: 'Yesterday' },
  { type: 'Proposal Due', text: 'Proposal due Date is Near for RFP Microsoft', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time' },
  { type: 'Proposal Due', text: 'Proposal due Date is Near for RFP Microsoft', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time' },
  { type: 'Proposal Due', text: 'Proposal due Date is Near for RFP Flipkart', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time' },
  { type: 'Proposal Due', text: 'Proposal due Date is Near for RFP Google', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time' },
  { type: 'Proposal Due', text: 'Proposal due Date is Near for RFP Amazon', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time' },
  { type: 'Proposal Due', text: 'Proposal due Date is Near for RFP Meta', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time' },
  { type: 'Proposal Due', text: 'Proposal due Date is Near for RFP Meta', subText: 'Preproposal Conference due on Jun-12-2024 01:PM EST, Please attend on time' },
];

function NotificationsPanel({ open, onClose }) {
  const [selectedTab, setSelectedTab] = useState(0);


  const handleChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const filteredNotifications = notifications.filter(notification => {
    if (selectedTab === 0) return notification.type === 'Conference Due';
    if (selectedTab === 1) return notification.type === 'RFP Questions Due';
    if (selectedTab === 2) return notification.type === 'Proposal Due';
    return false;
  });

  return (
    <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="notifications-modal-title"
    aria-describedby="notifications-modal-description"
  >
    <Container>
      <Box  sx={{ width: 650, height: 550, overflowY: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 2, borderRadius: '10px' }}>
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'baseline'}}>
         
          <Typography className="fw-bold fs-6  mb-1">Notifications</Typography>
          <IconButton
          onClick={onClose} 
         
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className="mb-3" sx={{ opacity: 0.6 }}></Divider>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 2, }}>
          <Badge color="error" variant="dot">
            <Button
              className="fw-bold"
              variant={selectedTab === 0 ? 'contained' : 'outlined'}
              sx={{
                color: selectedTab === 0 ? 'white' : 'rgb(1,117,255)',
                backgroundColor: selectedTab === 0 ? 'rgb(1,117,255)' : 'transparent',
                borderColor: 'rgb(1,117,255)',
                '&:hover': {
                  backgroundColor: selectedTab === 0 ? 'rgb(1,117,255)' : 'rgba(1,117,255,0.1)',
                  borderColor: 'rgb(1,117,255)',
                },
              }}
              onClick={() => handleChange(0)}
            >
              Conference Due (7)
            </Button>
          </Badge>

          <Button
            className="fw-bold"
            variant={selectedTab === 1 ? 'contained' : 'outlined'}
            color="primary"
            sx={{
              color: selectedTab === 1 ? 'white' : 'rgb(1,117,255)',
              backgroundColor: selectedTab === 1 ? 'rgb(1,117,255)' : 'transparent',
              borderColor: 'rgb(1,117,255)',
              '&:hover': {
                backgroundColor: selectedTab === 1 ? 'rgb(1,117,255)' : 'rgba(1,117,255,0.1)',
                borderColor: 'rgb(1,117,255)',
              },
            }}
            onClick={() => handleChange(1)}
          >
            RFP Questions Due (5)
          </Button>
          <Badge color="error" variant="dot">
            <Button
              className="fw-bold"
              variant={selectedTab === 2 ? 'contained' : 'outlined'}
              color="primary"
              sx={{
                color: selectedTab === 2 ? 'white' : 'rgb(1,117,255)',
                backgroundColor: selectedTab === 2 ? 'rgb(1,117,255)' : 'transparent',
                borderColor: 'rgb(1,117,255)',
                '&:hover': {
                  backgroundColor: selectedTab === 2 ? 'rgb(1,117,255)' : 'rgba(1,117,255,0.1)',
                  borderColor: 'rgb(1,117,255)',
                },
              }}
              onClick={() => handleChange(2)}
            >
              Proposal Due (7)
            </Button>
          </Badge>
        </Box>

        {filteredNotifications.map((notification, index) => (
          <React.Fragment key={index}>
            <Alert
              key={index}
              severity="none"
              icon={<img src={Danger} alt="DangerImage" width="18px" height="18px" />}
              sx={{
                padding: '8px 16px',
                alignItems: 'center',
                minHeight: '60px', // reduce the height
                '& .MuiAlert-message': {
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  justifyContent: 'space-between'
                },
              }}
            >
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <div>
                  <AlertTitle className="text-danger" sx={{ marginBottom: 0, fontSize: '14px' }}>{notification.text}</AlertTitle>
                  <Typography variant="body2" sx={{ fontSize: '12px', color: 'text.secondary' }}>{notification.subText}</Typography>
                </div>
                <div className="text-end">
                  <Typography variant="caption" sx={{ fontSize: '10px', color: 'text.secondary', paddingLeft: '30px' }}>{notification.time}</Typography>
                </div>

              </div>

            </Alert>
            {index < filteredNotifications.length - 1 && <Divider className='mt-1 mb-1' sx={{ opacity: 0.6 }} />}
          </React.Fragment>
        ))}

      </Box>
    </Container>
    </Modal>
  );
}

export default NotificationsPanel;
