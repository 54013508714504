import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  TextField,
  Divider,
  Paper,
  IconButton,
  CardContent,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Row, Col } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import CommentsImage from "../../assets/Images/CommentsImage.svg";
import MicrosoftRFP from "./ExtractRfpDocuments/MicrosoftRFP";
import PDFICON from "../../assets/Images/PDF.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import axios from "axios";
import "./Dashboard.css";
import Trash_Empty from "../../assets/Images/Trash_Empty.svg";
import ExtractIMG from "../../assets/Images/extract.svg";
import EYEIMG from "../../assets/Images/eye.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 4,
};

function RfpPreview() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [paused, setPaused] = useState(false);
  const [resumeRequested, setResumeRequested] = useState(false);
  const uploadTimer = useRef(null);
  const [documentType, setDocumentType] = useState("");
  const [fileName, setFileName] = useState("");
  const [comment, setComment] = useState();
  const [rowData, setRowData] = useState({});
  const [Commentcount, setCommentcount] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMicrosoftRFP, setShowMicrosoftRFP] = useState(false);
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);

  const statusMapping = {
    1: "Active",
    2: "Submitted",
    3: "OnHold",
    4: "InProgress",
  };

  const storedData = JSON.parse(localStorage.getItem("userDetails"));
  const userName = storedData.userDetails.userName;

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/listdocumnets/get_all_docs",
        {
          params: { rfpid: id },
        }
      );

      if (response.status === 200) {
        setDocuments(response.data.results);
        console.log(response.data.results);
      } else {
        console.error("Failed to fetch documents");
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const fetchrfpData = () => {
    axios
      .get(
        `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/fetchrfp?id=${id}`
      )
      .then((response) => {
        setRowData(response.data.details);
        console.log(response.data, "dataaa");
        setCommentcount(response.data.details.comments);
      })
      .catch((error) => {
        console.error("Error fetching RFP details:", error);
      });
  };

  useEffect(() => {
    fetchrfpData();
    fetchDocuments();
  }, []);

  const handleDeleteDocument = async () => {
    try {
      const response = await axios.delete(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/listdocumnets/del_doc",
        {
          data: {
            rfpid: id,
            doc_name: documentToDelete.doc_name,
          },
        }
      );
      console.log("Delete success:", response.data.message);
      fetchDocuments();
      handleCloseDeleteModal();
    } catch (error) {
      console.error("Delete failed:", error.response.data.message);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDeleteModal = (doc) => {
    setDocumentToDelete(doc);
    setDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setDocumentToDelete(null);
  };

  const handleBackdetails = () => {
    navigate("/dashboard");
  };
  const pdfOpenDeatils = (docUrl) => {
    console.log(docUrl, "before naviagting url");
    navigate(`/view-document/${encodeURIComponent(docUrl)}`);
    console.log(docUrl, "url");
  };

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
    setShowMicrosoftRFP(true);
  };
  const handleBackButtonClick = () => {
    setShowMicrosoftRFP(false); // Hide the MicrosoftRFP component
  };

  const handleEditPage = () => {
    navigate(`/MicrosoftRFPDetailsEditPage/${id}`, {
      state: { formData: rowData },
    });
  };

  const simulateFileUpload = useCallback((currentProgress = 0) => {
    uploadTimer.current = setInterval(() => {
      if (currentProgress < 90) {
        currentProgress += 5;
        setProgress(currentProgress); // Update progress bar
      }
    }, 500);
  }, []);

  const handleFileChange = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      simulateFileUpload();
      const base64 = await convertToBase64(uploadedFile);
      setFile({
        ...uploadedFile,
        base64,
        readableSize: formatFileSize(uploadedFile.size), // Format the file size
      });
      setFileName(uploadedFile.name);
    }
  };

  // Helper function to format file size
  const formatFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / Math.pow(1024, i)).toFixed(2)} ${["B", "KB", "MB", "GB", "TB"][i]}`;
  };

  const handleFileUpload = async () => {
    try {
      setLoading(true);
      setUploading(true);
      setIsCloseDisabled(true);
      const response = await axios.post(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/listdocumnets/add_doc",
        {
          rfpid: id,
          doc_name: fileName,
          doc_type: documentType,
          base64: file.base64,
        }
      );

      console.log("Upload success:", response);
      setFileUploadModalOpen(false);
      fetchDocuments();
      handleFileClose();
      setProgress(100);
    } catch (error) {
      console.error("Upload failed:", error.response);
      setProgress(0);
    } finally {
      setLoading(false); // Stop loading
      setUploading(false);
      clearInterval(uploadTimer.current);
      setIsCloseDisabled(false);
    }
  };

  const handleCancelUpload = () => {
    cleanUp();
    setProgress(0);
    setFile(null);
    document.getElementById("raised-button-file").value = ""; // Clear file input
  };

  const handlePauseUpload = () => {
    setPaused(true);
    setUploading(false);
    clearInterval(uploadTimer.current);
  };

  const handleResumeUpload = () => {
    if (paused) {
      setPaused(false);
      setResumeRequested(true);
    }
  };

  useEffect(() => {
    if (resumeRequested && !uploading) {
      setResumeRequested(false);
      simulateFileUpload(file, progress);
    }
  }, [resumeRequested, file, progress, uploading, simulateFileUpload]);

  const cleanUp = () => {
    clearInterval(uploadTimer.current);
    setUploading(false);
    setPaused(false);
  };

  const handleFileOpen = () => setFileUploadModalOpen(true);

  const handleFileClose = () => {
    cleanUp();
    setFile(null);
    setFileName("");
    setDocumentType("");
    setProgress(0);
    setLoading(false);
    document.getElementById("raised-button-file").value = "";
    setFileUploadModalOpen(false);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Removing data prefix
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const handleSaveComment = () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const userId = storedData.userDetails.id;
    const commentData = {
      userid: userId, // Replace with actual user ID
      comments: comment,
      rfpid: id,
    };
    axios
      .post(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/addrfpcomment",
        commentData
      )
      .then((response) => {
        console.log("Comment saved:", response.data);
        fetchrfpData();
        setComment("");
      })
      .catch((error) => {
        console.error("Error saving comment:", error.response.data);
      });
  };

  return (
    <Container>
      <div>
        <Card>
          <Box style={{ paddingBottom: "16px" }}>
            <div style={{ padding: "16px 0" }}>
              <Typography variant="h4" gutterBottom className="fontstylee mx-3">
                MicroSoft RFP Details
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 3, marginTop: -4 }}
                  className="text-primary border-1px primary border-primary rounded-2"
                  onClick={handleBackdetails}
                >
                  Back
                </Button>
              </Box>
            </div>
            <Container>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "16px",
                  position: "relative",
                  marginTop: "10px",
                }}
                className="mb-5"
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    background: "#fff",
                    padding: "0 8px",
                    fontSize: "15px",
                  }}
                  className="fontstyleeb"
                >
                  RFP Details
                </Typography>
                <Row className=" pb-4 pt-2">
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      First Review
                    </Typography>
                    <Typography>{rowData.first_review}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      RFP Issuer
                    </Typography>
                    <Typography>{rowData.rfp_issuer}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Rfp Type
                    </Typography>
                    <Typography>{rowData.rfp_type}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Location
                    </Typography>
                    <Typography>{rowData.location}</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Budget Range
                    </Typography>
                    <Typography>{rowData.budget_range}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Expected Timeline
                    </Typography>
                    <Typography>{rowData.expected_timeline}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">Website</Typography>
                    <Typography>
                      {" "}
                      <a
                        href={rowData.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {rowData.website}
                      </a>
                    </Typography>
                  </Col>
                </Row>
              </Box>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "16px",
                  position: "relative",
                }}
                className="mb-5"
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    background: "#fff",
                    padding: "0 8px",
                    fontSize: "15px",
                  }}
                  className="fontstyleeb"
                >
                  Point of Contact Details
                </Typography>
                <Row>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">Name</Typography>
                    <Typography>{rowData.contact_name}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">Email</Typography>
                    <Typography>{rowData.contact_email}</Typography>
                  </Col>
                </Row>
              </Box>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "16px",
                  marginBottom: "16px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    background: "#fff",
                    padding: "0 8px",
                    fontSize: "15px",
                  }}
                  className="fontstyleeb"
                >
                  Submission Details
                </Typography>
                <Row className="pb-4">
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Conference Date
                    </Typography>
                    <Typography>{rowData.conference_date}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Conference Information
                    </Typography>
                    <Typography>{rowData.conference_information}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Questionnaire Date
                    </Typography>
                    <Typography>{rowData.questionnaire_date}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">Status</Typography>
                    <Typography>{statusMapping[rowData.status]}</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Submission Mode
                    </Typography>
                    <Typography>{rowData.submission_mode}</Typography>
                  </Col>
                  <Col lg={3}>
                    <Typography className="fontstylee py-3">
                      Submission Date
                    </Typography>
                    <Typography>{rowData.submission_date}</Typography>
                  </Col>
                </Row>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Row className="py-3">
                  <Col lg={4} className="d-flex align-items-center mx-3">
                    <img
                      src={CommentsImage}
                      alt="commentsImage"
                      className="me-2"
                    />
                    <Typography
                      variant="h5"
                      onClick={handleOpen}
                      className="fontstylee previewCommentText text-primary"
                    >
                      Comments({Commentcount.length})
                    </Typography>
                  </Col>
                  <Col lg={3}></Col>
                  <Col lg={3} className="d-flex align-items-center">
                    <Badge
                      color="error"
                      badgeContent={2}
                      alt="NotificationIcon"
                      className="me-1"
                    >
                      <NotificationsIcon
                        fontSize="large"
                        className="text-primary"
                      />
                    </Badge>
                    {/* <Typography variant='h5' onClick={handleRemarkModalopen} className='previewRemarkText text-warning'>Remarks({remarkcount.length})</Typography> */}
                    <Typography
                      variant="h5"
                      className="fontstylee previewRemarkText text-primary"
                    >
                      Notifications
                    </Typography>
                  </Col>
                </Row>
                <Box>
                  <Button
                    variant="contained"
                    className="bg-primary rounded-2"
                    onClick={handleEditPage}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
        </Card>

        <Card className="mt-3" sx={{ height: 420 }}>
          <CardContent sx={{ height: "100%" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography className="fontstylee" variant="h4">
                List of Documents
              </Typography>
              <Button
                className="bg-primary text-capitalize rounded-2 mx-2"
                variant="contained"
                color="primary"
                onClick={handleFileOpen}
              >
                Add
              </Button>
            </Box>
            <Card>
              <TableContainer
                sx={{
                  width: "100%",
                  height: "335px",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    height: "3px",
                    width: "3px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "6px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                    cursor: "pointer",
                  },
                }}
              >
                <Table
                  aria-label="simple table"
                  stickyHeader
                  sx={{ width: "100%" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography className="fontstylee">
                          Document Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="fontstylee">
                          Document Type
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="fontstylee">
                          Uploaded Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className="fontstylee">Actions</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="position-relative">
                    {loading && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 1000,
                        }}
                      >
                        <CircularProgress className=" text-primary" />
                      </Box>
                    )}
                    {documents.length === 0 && !loading ? (
                      <Box
                        sx={{
                          position: "absolute", // Absolute position ensures it covers the table
                          top: 100,
                          left: 450,
                        }}
                      >
                        <Typography variant="h6">No Records Found</Typography>
                      </Box>
                    ) : (
                      documents.map((doc, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography
                              onClick={() => handleDocumentClick(doc)}
                              className="fontstylee text-primary documentname"
                            >
                              {doc.doc_name}
                            </Typography>
                          </TableCell>
                          <TableCell>{doc.doc_type}</TableCell>
                          <TableCell>{doc.uploaded_on}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => pdfOpenDeatils(doc.doc_url)}
                            >
                              <img
                                src={EYEIMG}
                                width="31px"
                                height="31px"
                                alt="eyeimg"
                                style={{
                                  backgroundColor: "#D9EAFF",
                                  borderRadius: "38%",
                                  padding: "7px",
                                }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleOpenDeleteModal(doc)}
                            >
                              <img
                                src={Trash_Empty}
                                width="31px"
                                height="31px"
                                alt="trshimg"
                                style={{
                                  backgroundColor: "#FCD9D9",
                                  borderRadius: "38%",
                                  padding: "7px",
                                }}
                              />
                            </IconButton>

                            <IconButton
                              onClick={() => handleDocumentClick(doc)}
                            >
                              <img
                                src={ExtractIMG}
                                width="31px"
                                height="31px"
                                alt="extractimg"
                                style={{
                                  backgroundColor: "#DCF1E4",
                                  borderRadius: "38%",
                                  padding: "7px",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </CardContent>
        </Card>
        <Card className="mt-3">
          {showMicrosoftRFP && (
            <Card className="mt-3">
              <MicrosoftRFP
                document={selectedDocument}
                onBackButtonClick={handleBackButtonClick} // Pass the back button handler
              />
            </Card>
          )}
        </Card>

        {/* Comments Model */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography gutterBottom className="commentsText">
                Comments
              </Typography>
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Box>
            <Divider style={{ borderColor: "#adadad" }} />
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className="addCommentText"
            >
              Add Comments
            </Typography>
            <TextField
              fullWidth
              label="Comment"
              variant="outlined"
              className=" mb-2 rounded-5 mt-3"
              multiline
              rows={2}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px", // Set the desired border-radius
                },
              }}
            />
            <Button
              variant="contained"
              className="mb-3 float-end bg-primary rounded-5 "
              onClick={handleSaveComment}
            >
              Save
            </Button>
            <Typography className="mt-4 fs-6">Previous Comment list</Typography>
            <TableContainer
              component={Paper}
              sx={{
                borderColor: "ActiveBorder",
                borderRadius: "12px",
                marginTop: "15px",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="remarkstext">
                      Comment
                    </TableCell>
                    <TableCell align="left" className="remarkstext">
                      Commented Date
                    </TableCell>
                    <TableCell align="left" className="remarkstext">
                      Comment By
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Commentcount.map((comment, index) => (
                    <TableRow key={comment.id}>
                      <TableCell align="left">{comment.comments}</TableCell>
                      <TableCell align="left">{comment.comment_date}</TableCell>
                      <TableCell align="left">{userName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>

        <Modal
          open={fileUploadModalOpen}
          onClose={handleFileClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{ ...style, width: "550px" }}>
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                }}
              >
                <CircularProgress className="text-primary" />
              </Box>
            )}
            <IconButton
              aria-label="close"
              onClick={handleFileClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 5,
                color: (theme) => theme.palette.grey[500],
              }}
              disabled={isCloseDisabled}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="h2"
              textAlign="left"
              className="fontstyleeb fs-6 pb-1"
              sx={{ marginTop: "-13px" }}
            >
              Add New Document
            </Typography>
            <Divider />
            <Row>
              <Col lg={7}>
                <FormControl fullWidth margin="normal">
                  <Typography>Document Name</Typography>
                  <TextField
                    placeholder="Document Name"
                    name="DocName"
                    value={fileName}
                    onChange={handleFileNameChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "16px",
                      },
                    }}
                  />
                </FormControl>
              </Col>
              <Col lg={5}>
                <FormControl fullWidth margin="normal">
                  <Typography>Document Type</Typography>
                  <Select
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    name="submissionMode"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px",
                      },
                      "& .MuiSelect-icon": {
                        color: "blue",
                      },
                    }}
                  >
                    <MenuItem value="Addendum">Addendum</MenuItem>
                    <MenuItem value="Support">Support</MenuItem>
                    <MenuItem value="Final/Response">Final/Response</MenuItem>
                    <MenuItem value="RFP">RFP</MenuItem>
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Box
              sx={{
                backgroundColor: "rgba(215, 233, 245)",
                border: "1px dashed blue",
                borderRadius: "5px",
                textAlign: "center",
                p: 6,
                mt: 1,
                mb: 2,
                position: "relative",
                width: "100%",
              }}
            >
              <Box
                onClick={() =>
                  document.getElementById("raised-button-file").click()
                }
              >
                <img
                  src={PDFICON}
                  alt="PDFICON"
                  width={"70px"}
                  height={"84px"}
                  style={{ cursor: "pointer" }}
                  className="pb-2"
                />
              </Box>

              {!file && (
                <>
                  <Typography>
                    Drag and Drop your File here, or{" "}
                    <label
                      htmlFor="raised-button-file"
                      style={{ color: "#007bff", cursor: "pointer" }}
                    >
                      Browse Files
                    </label>
                  </Typography>
                  <Typography sx={{ color: "#888", fontSize: "12px" }}>
                    Limit 200MB per file • PDF Files Only
                  </Typography>
                </>
              )}

              {file && (
                <>
                  <Typography sx={{ fontSize: "14px", mt: 1 }}>
                    {fileName || file.name} {/* Display file name */}
                  </Typography>
                  <Typography sx={{ color: "#888", fontSize: "12px" }}>
                    ({file.readableSize}) {/* Display formatted file size */}
                  </Typography>
                </>
              )}

              <input
                accept=".pdf"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
              />
            </Box>
            {uploading && (
              <Box sx={{ width: "100%" }}>
                <div className="border border-1px solid grey p-2">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        src={PDFICON}
                        alt="PDFICON"
                        width={"30px"}
                        height={"42px"}
                        style={{ cursor: "pointer" }}
                        className="pb-2 mx-1"
                      />
                      <div
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography style={{ textDecoration: "none" }}>
                          {fileName || file.name}
                          {file?.readableSize || "Size unknown"}{" "}
                          {/* Display file size during upload */}
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#4caf50",
                      },
                      mt: 1,
                    }}
                  />
                </div>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <div>
                {/* Only show the Save button when document name and type are selected */}
                {file && fileName && documentType && (
                  <Button
                    color="primary"
                    onClick={handleFileUpload}
                    className="rounded-2 bg-primary text-white"
                  >
                    Save
                  </Button>
                )}
              </div>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={deleteModalOpen}
          onClose={handleCloseDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: "500px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <CloseIcon
                onClick={handleCloseDeleteModal}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Divider style={{ borderColor: "#adadad" }} />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete "{documentToDelete?.doc_name}"?
            </Typography>
            <Box mt={4} display="flex" justifyContent="end">
              <Button
                variant="contained"
                className="bg-primary mx-2 rounded-3"
                onClick={handleDeleteDocument}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                className="border-primary text-primary rounded-3"
                onClick={handleCloseDeleteModal}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </Container>
  );
}
export default RfpPreview;
