import React, { useState } from 'react';
import { Container, Typography, Paper, Button, Box, Link, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider } from '@mui/material';
import { useNavigate,useLocation,useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PDF from '../../assets/Images/PDF.svg';
 
const SectionDetails = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { id } = useParams(); // Get the id from URL
  const { state } = useLocation(); // Get the passed data using useLocation
 
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
 
  const versionHistory = [
    { id: 1, version: 'Version 5', date: 'Aug-27-2024, 1:00 PM IST' },
    { id: 2, version: 'Version 4', date: 'Aug-26-2024, 3:45 PM IST' },
    { id: 3, version: 'Version 3', date: 'Aug-23-2024, 5:00 PM IST' },
    { id: 4, version: 'Version 2', date: 'Aug-12-2024, 8:23 PM IST' },
    { id: 5, version: 'Version 1', date: 'Aug-09-2024, 12:00 PM IST' },
  ];
 
  const Sidebar = ({ onClose }) => (
    <Box p={3} width="400px" role="presentation">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" className='fontstyleeb' gutterBottom >
          Version History
        </Typography>
 
        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
      </Box>
      <Divider sx={{ marginBottom: 1 }} />
      <TableContainer component={Paper} sx={{ borderColor: 'ActiveBorder', borderRadius: '20px', marginTop: '15px' }}>
        <Table siz>
          <TableHead>
            <TableRow>
              <TableCell className='fontstyleeb'>S.No</TableCell>
              <TableCell className='fontstyleeb'>Version</TableCell>
              <TableCell className='fontstyleeb'>Updated On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versionHistory.map((version, index) => (
              <TableRow key={version.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{version.version}</TableCell>
                <TableCell>{version.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
 
      <Divider style={{ marginTop: '34rex', borderColor: 'black' }} />
      <Row style={{ marginTop: '15px' }}>
 
        <Col lg={{ span: 3, offset: 9 }}>
          <Button
            variant="contained"
            className='bg-primary text-capitalize rounded-3'
            fullWidth
            onClick={onClose}
          >
            Close
          </Button>
        </Col>
      </Row>
 
    </Box>
  );
 
  return (
    <Container maxWidth="100%">
      <Box mt={2}>
        <Paper elevation={3} style={{ padding: 20, borderRadius: '15px' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography className='fontstyleeb' variant="h4" gutterBottom>
              Section Details
            </Typography>
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              sx={{ marginRight: 2, color: '#429bf5', borderColor: '#429bf5' }}
              className='border-primary text-capitalize rounded-3'
            >
              Back
            </Button>
          </Box>
          {/* <Box display="flex" justifyContent="space-between">
          </Box> */}
          <Box className='d-flex mb-4 mt-2'>
            <Box >
              <Typography className='fontstylee'>
                Section Name
              </Typography>
              <Typography>
              {state?.row?.content_name}
              </Typography>
            </Box>
            <Box className='mx-4'>
              <Typography className='fontstylee' variant="subtitle1">
                Last Updated{' '}
                <Link onClick={toggleSidebar} className='link-underline' style={{ cursor: 'pointer', color: '#0175FF' }}>
                  Version History
                </Link>
              </Typography>
              <Typography variant="subtitle1">
                Jun-12-2024, 02:00 PM IST
              </Typography>
            </Box>
          </Box>
          <Typography className='fontstylee' gutterBottom>
            Description
          </Typography>
          <Typography
            variant="body1"
            paragraph
            dangerouslySetInnerHTML={{ __html: state?.row?.content_description }}
          />
          <Typography variant="h6" gutterBottom>
            Attachments
          </Typography>
          <Box alignItems="center" mb={2}>
            <img src={PDF} alt="moreicon" style={{ marginLeft: 10 }} />
            <Typography variant="body1">Cv.pdf 12 MB</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={() => navigate(-1)} className='border-primary text-capitalize rounded-3' sx={{ marginRight: 2, color: '#0D6EFD' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(`/edit-section-details/${id}`, { state: { sectionName: state?.row?.content_name, description: state?.row?.content_description } })}
              sx={{ bgcolor: '#429bf5' }}
              className='bg-primary text-capitalize rounded-3'
            >
              Edit
            </Button>
          </Box>
        </Paper>
      </Box>
      <Drawer anchor="right" open={isSidebarOpen} onClose={toggleSidebar}>
        <Sidebar onClose={toggleSidebar} />
      </Drawer>
    </Container>
  );
};
 
export default SectionDetails;